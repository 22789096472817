import { jsxs, jsx, Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import classNames from "classnames";
const getDatePickerQuickReply = (date) => JSON.stringify({
  en: { type: "DATE_PICKER_RESPONSE", selectedDate: date }
});
const THIS_YEAR = +(/* @__PURE__ */ new Date()).getFullYear();
const THIS_MONTH = +(/* @__PURE__ */ new Date()).getMonth() + 1;
var WEEK_DAYS = /* @__PURE__ */ ((WEEK_DAYS2) => {
  WEEK_DAYS2["MONDAY"] = "Monday";
  WEEK_DAYS2["TUESDAY"] = "Tuesday";
  WEEK_DAYS2["WEDNESDAY"] = "Wednesday";
  WEEK_DAYS2["THRUSDAY"] = "Thursday";
  WEEK_DAYS2["FRIDAY"] = "Friday";
  WEEK_DAYS2["SATURDAY"] = "Saturday";
  WEEK_DAYS2["SUNDAY"] = "Sunday";
  return WEEK_DAYS2;
})(WEEK_DAYS || {});
const CALENDAR_WEEKS = 6;
const zeroPad = (value, length) => {
  return `${value}`.padStart(length, "0");
};
const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [4, 6, 9, 11];
  const leapYear = year % 4 === 0;
  return month === 2 ? leapYear ? 29 : 28 : months30.includes(month) ? 30 : 31;
};
const getMonthFirstWeekday = (month = THIS_MONTH, year = THIS_YEAR) => {
  const date = /* @__PURE__ */ new Date(`${year}-${zeroPad(month, 2)}-01`);
  return (date.getDay() + 6) % 7 + 1;
};
const isDate = (date) => {
  const isDate2 = Object.prototype.toString.call(date) === "[object Date]";
  const isValidDate = date && !Number.isNaN(date.valueOf());
  return isDate2 && isValidDate;
};
const isSameMonth = (date, basedate = /* @__PURE__ */ new Date()) => {
  if (!(isDate(date) && isDate(basedate)))
    return false;
  const basedateMonth = +basedate.getMonth() + 1;
  const basedateYear = basedate.getFullYear();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();
  return +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};
const isSameDay = (date, basedate = /* @__PURE__ */ new Date()) => {
  if (!(isDate(date) && isDate(basedate)))
    return false;
  const basedateDate = basedate.getDate();
  const basedateMonth = +basedate.getMonth() + 1;
  const basedateYear = basedate.getFullYear();
  const dateDate = date.getDate();
  const dateMonth = +date.getMonth() + 1;
  const dateYear = date.getFullYear();
  return +basedateDate === +dateDate && +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};
const reverseDateFormat = (dateString = "") => {
  if (!dateString)
    return "";
  return dateString.split("-").reverse().join("-");
};
const formatDateWithDash = (dateString) => {
  if (!dateString)
    return "";
  return dateString.split("/").join("-");
};
const getDateISO = (date = /* @__PURE__ */ new Date()) => {
  if (!isDate(date))
    return null;
  return [date.getFullYear(), zeroPad(+date.getMonth() + 1, 2), zeroPad(+date.getDate(), 2)].join(
    "-"
  );
};
const getPreviousMonth = (month, year) => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;
  return { month: prevMonth, year: prevMonthYear };
};
const getNextMonth = (month, year) => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;
  return { month: nextMonth, year: nextMonthYear };
};
const calendar = (month = THIS_MONTH, year = THIS_YEAR) => {
  const monthDays = getMonthDays(month, year);
  const monthFirstWeekday = getMonthFirstWeekday(month, year);
  const daysFromPrevMonth = monthFirstWeekday - 1;
  const daysFromNextMonth = CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);
  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);
  const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
    return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
  });
  const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
    const day = index + 1;
    return [year, zeroPad(month, 2), zeroPad(day, 2)];
  });
  const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
    const day = index + 1;
    return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
  });
  return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
const CalendarGrid = ({
  TODAY,
  selectedDate,
  currentMonth,
  currentYear,
  getCalendarDates,
  gotoDate,
  enableDatesInPast
}) => {
  const renderDayLabel = (day) => {
    const dayLabel = WEEK_DAYS[day].toUpperCase();
    return /* @__PURE__ */ jsx("div", { className: "calendar-cell calendar-day", children: dayLabel[0] }, dayLabel);
  };
  const renderCalendarCell = (data) => {
    const parsedData = data.join("-");
    const cellDate = new Date(parsedData);
    const isToday = isSameDay(cellDate, TODAY);
    const isCurrent = selectedDate && isSameDay(cellDate, selectedDate);
    const inMonth = currentMonth && currentYear && isSameMonth(cellDate, new Date([currentYear, currentMonth, 1].join("-")));
    const isInPast = !isToday && cellDate.getTime() < TODAY.getTime();
    const isDisabled = !enableDatesInPast && isInPast;
    return /* @__PURE__ */ jsx(
      "button",
      {
        disabled: isDisabled,
        className: classNames({
          "calendar-cell": true,
          "is-highlighted": isCurrent,
          "is-in-month": inMonth,
          "is-today": isToday
        }),
        onClick: () => gotoDate(cellDate),
        title: cellDate.toDateString(),
        children: cellDate.getDate()
      },
      getDateISO(cellDate)
    );
  };
  return /* @__PURE__ */ jsxs("div", { className: "sa-chat-calendar-grid", children: [
    Object.keys(WEEK_DAYS).map(renderDayLabel),
    getCalendarDates().map((day) => renderCalendarCell(day))
  ] });
};
const LeftArrow = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='24px'%20height='24px'%20viewBox='0%200%2024%2024'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2059%20(86127)%20-%20https://sketch.com%20--%3e%3ctitle%3eGraphic%20/%20Icon%20/%20Arrow%20/%20Left%20/%20Default%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cdefs%3e%3cpath%20d='M7.56203275,9.27152367%20L11.979,13.688%20L16.4624245,9.35902755%20C16.8271652,9.0068015%2017.4043444,9.01276602%2017.7570445,9.37799768%20C18.1073027,9.74070063%2018.099788,10.3161844%2017.7308215,10.6724911%20L12.6553982,15.5737705%20C12.4378336,15.7838702%2012.1446829,15.8665251%2011.8686745,15.8215067%20C11.648701,15.8123192%2011.4307715,15.7225163%2011.260017,15.5517618%20L6.27090578,10.5626506%20C5.91236778,10.2041126%205.90825822,9.62691724%206.26727878,9.26789668%20C6.62381365,8.91136181%207.1993409,8.90883182%207.56203275,9.27152367%20Z'%20id='path-1'%3e%3c/path%3e%3c/defs%3e%3cg%20id='Graphic-/-Icon-/-Arrow-/-Left-/-Default'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cmask%20id='mask-2'%20fill='white'%3e%3cuse%20xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse%20id='Icon'%20fill='%23556272'%20fill-rule='nonzero'%20transform='translate(12.006895,%2012.416707)%20rotate(-270.000000)%20translate(-12.006895,%20-12.416707)%20'%20xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/svg%3e";
const RightArrow = "data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20width='24px'%20height='24px'%20viewBox='0%200%2024%2024'%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!--%20Generator:%20Sketch%2059%20(86127)%20-%20https://sketch.com%20--%3e%3ctitle%3eGraphic%20/%20Icon%20/%20Arrow%20/%20Right%20/%20Default%3c/title%3e%3cdesc%3eCreated%20with%20Sketch.%3c/desc%3e%3cdefs%3e%3cpath%20d='M7.56203275,9.27152367%20L11.979,13.688%20L16.4624245,9.35902755%20C16.8271652,9.0068015%2017.4043444,9.01276602%2017.7570445,9.37799768%20C18.1073027,9.74070063%2018.099788,10.3161844%2017.7308215,10.6724911%20L12.6553982,15.5737705%20C12.4378336,15.7838702%2012.1446829,15.8665251%2011.8686745,15.8215067%20C11.648701,15.8123192%2011.4307715,15.7225163%2011.260017,15.5517618%20L6.27090578,10.5626506%20C5.91236778,10.2041126%205.90825822,9.62691724%206.26727878,9.26789668%20C6.62381365,8.91136181%207.1993409,8.90883182%207.56203275,9.27152367%20Z'%20id='path-1'%3e%3c/path%3e%3c/defs%3e%3cg%20id='Graphic-/-Icon-/-Arrow-/-Right-/-Default'%20stroke='none'%20stroke-width='1'%20fill='none'%20fill-rule='evenodd'%3e%3cmask%20id='mask-2'%20fill='white'%3e%3cuse%20xlink:href='%23path-1'%3e%3c/use%3e%3c/mask%3e%3cuse%20id='Icon'%20fill='%23556272'%20fill-rule='nonzero'%20transform='translate(12.006895,%2012.416707)%20rotate(-90.000000)%20translate(-12.006895,%20-12.416707)%20'%20xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/svg%3e";
const CALENDAR_MONTHS = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec"
};
const CalendarHeader = ({ month, year, handlePrevious, handleNext }) => {
  const monthName = Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
  return /* @__PURE__ */ jsxs("div", { className: "sa-chat-calendar-header", children: [
    /* @__PURE__ */ jsx("button", { className: "arrow left", onClick: handlePrevious, title: "Previous Month", children: /* @__PURE__ */ jsx("img", { src: LeftArrow, alt: "Left arrow" }) }),
    /* @__PURE__ */ jsx("div", { className: "calendar-month", "aria-label": "calendar-month", children: /* @__PURE__ */ jsxs("span", { title: "Month", children: [
      monthName,
      " ",
      year
    ] }) }),
    /* @__PURE__ */ jsx("button", { className: "arrow right", onClick: handleNext, title: "Next Month", children: /* @__PURE__ */ jsx("img", { src: RightArrow, alt: "Right arrow" }) })
  ] });
};
const Calendar = ({
  data,
  onDateChanged,
  enableDatesInPast
}) => {
  const TODAY = /* @__PURE__ */ new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(TODAY.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(TODAY.getFullYear());
  const resolveStateFromDate = (date) => {
    setSelectedDate(date);
    setCurrentMonth(+date.getMonth() + 1);
    setCurrentYear(date.getFullYear());
  };
  const getCalendarDates = () => {
    const calendarMonth = currentMonth || +selectedDate.getMonth() + 1;
    const calendarYear = currentYear || selectedDate.getFullYear();
    return calendar(calendarMonth, calendarYear);
  };
  const gotoDate = (date) => {
    if (!isSameDay(date, selectedDate)) {
      onDateChanged(date.toDateString());
      resolveStateFromDate(date);
    }
  };
  const gotoPreviousMonth = () => {
    const newMonthAndYear = getPreviousMonth(currentMonth, currentYear);
    setCurrentMonth(newMonthAndYear.month);
    setCurrentYear(newMonthAndYear.year);
  };
  const gotoNextMonth = () => {
    const newMonthAndYear = getNextMonth(currentMonth, currentYear);
    setCurrentMonth(newMonthAndYear.month);
    setCurrentYear(newMonthAndYear.year);
  };
  const gotoPreviousYear = () => {
    setCurrentYear(currentYear - 1);
  };
  const gotoNextYear = () => {
    setCurrentYear(currentYear + 1);
  };
  const handlePrevious = (evt) => {
    evt == null ? void 0 : evt.preventDefault();
    evt.shiftKey ? gotoPreviousYear() : gotoPreviousMonth();
  };
  const handleNext = (evt) => {
    evt == null ? void 0 : evt.preventDefault();
    evt.shiftKey ? gotoNextYear() : gotoNextMonth();
  };
  return /* @__PURE__ */ jsxs("div", { className: "sa-chat-calendar-container", children: [
    /* @__PURE__ */ jsx(
      CalendarHeader,
      {
        month: currentMonth,
        year: currentYear,
        handleNext,
        handlePrevious
      }
    ),
    /* @__PURE__ */ jsx(
      CalendarGrid,
      {
        TODAY,
        selectedDate,
        currentMonth,
        currentYear,
        getCalendarDates,
        gotoDate,
        enableDatesInPast
      }
    )
  ] });
};
const DatePicker = ({
  nextAvailableDate = getDateISO(/* @__PURE__ */ new Date()),
  onConfirm,
  enableDatesInPast = true
}) => {
  const [date, setDate] = useState(null);
  const [isDateConfirmed, setIsDateConfirmed] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      if (typeof nextAvailableDate !== "string")
        return;
      if (nextAvailableDate.split("-")[2].length === 4) {
        nextAvailableDate = reverseDateFormat(nextAvailableDate);
      }
      const newDate = isDate(new Date(nextAvailableDate)) ? new Date(nextAvailableDate) : "";
      newDate && setDate(getDateISO(newDate));
    } else {
      if (date !== nextAvailableDate) {
        setDate(nextAvailableDate);
        setIsDateConfirmed(false);
      }
    }
  }, [mounted]);
  const handleDateChange = (selectedDate) => {
    selectedDate && setDate(selectedDate);
  };
  const handleConfirm = () => {
    if (!date)
      return null;
    setIsDateConfirmed(true);
    setDateSelected(true);
    return onConfirm(getDatePickerQuickReply(date));
  };
  return /* @__PURE__ */ jsxs("div", { className: "sa-chat-date-picker", children: [
    /* @__PURE__ */ jsx(
      Calendar,
      {
        data: date,
        onDateChanged: handleDateChange,
        enableDatesInPast
      }
    ),
    /* @__PURE__ */ jsx(
      "button",
      {
        className: "confirm-button",
        "aria-label": "confirm-button",
        type: "button",
        onClick: handleConfirm,
        "data-is-confirmed": isDateConfirmed,
        disabled: dateSelected,
        children: "Continue"
      }
    )
  ] });
};
const DatePickerMessage = ({
  ariaId,
  nextAvailableDate,
  title,
  enableDatesInPast = true,
  sendMessage
}) => {
  return /* @__PURE__ */ jsx("div", { id: ariaId, className: "sa-chat-date-picker-message", children: title ? /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx("div", { children: title }),
    /* @__PURE__ */ jsx(
      DatePicker,
      {
        nextAvailableDate,
        onConfirm: sendMessage,
        enableDatesInPast
      }
    )
  ] }) : /* @__PURE__ */ jsx(
    DatePicker,
    {
      nextAvailableDate,
      onConfirm: sendMessage,
      enableDatesInPast
    }
  ) });
};
export {
  DatePickerMessage as D,
  formatDateWithDash as f,
  isDate as i
};
