import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EditorState } from 'draft-js'
import { IAttachment } from 'store/tasks/tasks.state'
import { OutboundEmailForm } from './outboundEmail.state'

const initialState: OutboundEmailForm = {
    emailTextState: EditorState.createEmpty(),
    from: '',
    to: '',
    subject: '',
    attachments: [],
    sendingMessage: false,
    messageSent: false,
    identities: [],
    identitiesLoading: false,
}

export const outboundEmailSlice = createSlice({
    name: 'outboundEmail',
    initialState,
    reducers: {
        setEmailTextState(state, action: PayloadAction<EditorState>) {
            return { ...state, emailTextState: action.payload }
        },
        setFrom(state, action: PayloadAction<string>) {
            return { ...state, from: action.payload }
        },
        setTo(state, action: PayloadAction<string>) {
            return { ...state, to: action.payload }
        },
        setSubject(state, action: PayloadAction<string>) {
            return { ...state, subject: action.payload }
        },
        setAttachments(state, action: PayloadAction<IAttachment[]>) {
            return { ...state, attachments: action.payload }
        },
        setSendingMessage(state, action: PayloadAction<boolean>) {
            return { ...state, sendingMessage: action.payload }
        },
        setMessageSent(state, action: PayloadAction<boolean>) {
            return { ...state, messageSent: action.payload }
        },
        setIdentities(state, action: PayloadAction<string[]>) {
            return { ...state, identities: action.payload }
        },
        setIdentitiesLoading(state, action: PayloadAction<boolean>) {
            return { ...state, identitiesLoading: action.payload }
        },
    },
})

export const {
    setEmailTextState,
    setFrom,
    setTo,
    setSubject,
    setAttachments,
    setSendingMessage,
    setMessageSent,
    setIdentities,
    setIdentitiesLoading,
} = outboundEmailSlice.actions

export default outboundEmailSlice.reducer
