import { jsxs, jsx } from "react/jsx-runtime";
import { formatDateTime } from "@missionlabs/smartagent-app-components";
import classNames from "classnames";
import { useState, useLayoutEffect, useRef, useEffect } from "react";
import { T as TransformContent } from "./TransformContent-CKBSPwXP.mjs";
import { M as MessageUserType } from "./chat-message.types-V2YsTtfG.mjs";
import QuickReplies from "./QuickReplies.mjs";
import QuickRepliesMultiLine from "./QuickRepliesMultiLine.mjs";
import useResizeObserver from "@react-hook/resize-observer";
import { u as useMessageReceipt } from "./useMessageReceipt-CHz50bPl.mjs";
const useElementSize = (target) => {
  const [size, setSize] = useState();
  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);
  useResizeObserver(target, (entry) => {
    const [{ inlineSize: width, blockSize: height }] = entry.borderBoxSize;
    setSize({
      width,
      height
    });
  });
  return [size == null ? void 0 : size.width, size == null ? void 0 : size.height];
};
const TranslatedMessage = ({
  message,
  translatedFromOriginal = true,
  isMarkdownEnabled,
  isHTMLEnabled,
  translations
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const translatedTarget = translatedFromOriginal ? "original" : "translated";
  return /* @__PURE__ */ jsxs("div", { className: "sa-translated-message", children: [
    /* @__PURE__ */ jsxs(
      "button",
      {
        className: "sa-translated-message-button",
        onClick: () => setIsOpen(!isOpen),
        children: [
          /* @__PURE__ */ jsx("span", { children: isOpen ? `${translations.hideText} ${translatedTarget} ${translations.messageText}` : `${translations.seeText} ${translatedTarget} ${translations.messageText}` }),
          /* @__PURE__ */ jsx(
            "svg",
            {
              width: "13",
              height: "8",
              viewBox: "0 0 10 6",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
              className: classNames("sa-translated-message-caret", {
                "sa-translated-message-caret-flipped": isOpen
              }),
              children: /* @__PURE__ */ jsx("path", { d: "M2.03678 0.903184L4.98142 3.8475L7.97037 0.96152C8.21353 0.726703 8.59832 0.730679 8.83345 0.974166C9.06696 1.21597 9.06195 1.59962 8.81597 1.83716L5.43235 5.10468C5.28731 5.24475 5.09188 5.29985 4.90787 5.26984C4.76122 5.26371 4.61594 5.20385 4.5021 5.09001L1.17603 1.76394C0.937001 1.52491 0.934261 1.14011 1.17361 0.900766C1.4113 0.663076 1.79498 0.661389 2.03678 0.903184Z" })
            }
          )
        ]
      }
    ),
    isOpen && /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(TransformContent, { text: message, ...{ isMarkdownEnabled, isHTMLEnabled } }) })
  ] });
};
const getSentAt = (date) => formatDateTime(new Date(date), { date: false, time: true });
const PlainTextChatMessage = ({
  ariaId,
  ariaContentId,
  senderType,
  clientType,
  text,
  time,
  companyLogoUrl,
  userIconUrl,
  senderDisplayName,
  isMarkdownEnabled,
  isHTMLEnabled,
  translatedMessage,
  translatedFromOriginal,
  quickReplies,
  showQuickRepliesOnMount = true,
  multilineQuickReplies,
  sendMessage,
  messageHeader,
  onMessageSizeChange,
  receiptsActive,
  deliveredTimestamp,
  readTimestamp,
  customMessageStyle,
  ignoreTransformToLinkTexts,
  translations
}) => {
  const messageBoxClasses = classNames(
    {
      "sa-chat-message-text": true,
      "sa-chat-message-text__agent": senderType === MessageUserType.AGENT || senderType === MessageUserType.SYSTEM,
      "sa-chat-message-text__customer": senderType === MessageUserType.CUSTOMER
    },
    customMessageStyle
  );
  const messageTimeSent = getSentAt(time);
  const messageType = `${translations.messageText} ${senderType === MessageUserType.AGENT || senderType === MessageUserType.SYSTEM ? translations.receivedAtText : translations.sentAtText} ${messageTimeSent}.`;
  const message = useRef(null);
  const [width, height] = useElementSize(message);
  useEffect(() => {
    if (width || height) {
      onMessageSizeChange == null ? void 0 : onMessageSizeChange({ width, height });
    }
  }, [width, height]);
  const { receiptStatus, receiptIcon, showReceipt } = useMessageReceipt({
    readTimestamp,
    deliveredTimestamp,
    receiptsActive,
    clientType,
    senderType,
    translations: { statusLabels: translations.statusLabels }
  });
  return /* @__PURE__ */ jsxs("div", { className: "sa-chat-message-text-external-wrapper", children: [
    /* @__PURE__ */ jsxs("div", { id: ariaId, className: "sa-chat-message-text-wrapper", children: [
      (senderType === MessageUserType.AGENT || senderType === MessageUserType.SYSTEM) ?? /* @__PURE__ */ jsx("span", { className: "sa-chat-screen-reader-only", "aria-live": "polite", children: translations.newMessageNotificationText }),
      clientType === MessageUserType.CUSTOMER && senderType !== MessageUserType.CUSTOMER && !!companyLogoUrl && /* @__PURE__ */ jsx(
        "img",
        {
          className: "sa-chat-message-text-wrapper-company-logo",
          "aria-hidden": "true",
          src: companyLogoUrl,
          alt: ""
        }
      ),
      /* @__PURE__ */ jsxs("div", { className: "sa-chat-message-text-wrapper-content", children: [
        /* @__PURE__ */ jsxs("div", { className: "sa-chat-message-text-wrapper-content-message", children: [
          /* @__PURE__ */ jsxs("div", { className: "sa-chat-message-text-wrapper-inner", children: [
            senderDisplayName ? /* @__PURE__ */ jsx(
              "span",
              {
                className: "sa-chat-message-text-wrapper-display-name",
                "aria-hidden": "true",
                children: senderDisplayName
              }
            ) : messageHeader && messageHeader,
            /* @__PURE__ */ jsxs(
              "div",
              {
                "aria-label": senderType === MessageUserType.CUSTOMER && text.includes("**") ? "encrypted text" : "",
                className: messageBoxClasses,
                ref: message,
                "data-testid": "plain-message-rendered-testId",
                children: [
                  /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
                    TransformContent,
                    {
                      ...{
                        isMarkdownEnabled,
                        isHTMLEnabled,
                        text,
                        ignoreTransformToLinkTexts
                      }
                    }
                  ) }),
                  /* @__PURE__ */ jsxs("div", { className: "timestamp-container", children: [
                    /* @__PURE__ */ jsx("time", { "aria-hidden": "true", "data-testid": "message-time-sent", children: messageTimeSent }),
                    /* @__PURE__ */ jsx("span", { className: "sa-chat-screen-reader-only", children: messageType }),
                    showReceipt && /* @__PURE__ */ jsx(
                      "div",
                      {
                        className: classNames({
                          "message-receipt-icon": true,
                          "message-receipt-icon-visible": [
                            "Read",
                            "Delivered"
                          ].includes(receiptStatus)
                        }),
                        title: receiptStatus,
                        children: receiptIcon
                      }
                    )
                  ] })
                ]
              }
            )
          ] }),
          userIconUrl && senderType === MessageUserType.CUSTOMER && clientType !== MessageUserType.AGENT && /* @__PURE__ */ jsx("div", { className: "chat-user", children: /* @__PURE__ */ jsx("img", { "aria-hidden": "true", src: userIconUrl, alt: "" }) })
        ] }),
        translatedMessage && translatedMessage !== text && /* @__PURE__ */ jsx(
          TranslatedMessage,
          {
            translations: {
              hideText: translations.hideText,
              messageText: translations.messageText.toLowerCase(),
              seeText: translations.seeText
            },
            message: translatedMessage,
            ...{ isMarkdownEnabled, isHTMLEnabled, translatedFromOriginal }
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx("div", { className: "sa-chat-message-quick-replies-wrapper", children: quickReplies && (quickReplies == null ? void 0 : quickReplies.length) > 0 && sendMessage && (multilineQuickReplies ? /* @__PURE__ */ jsx(
      QuickRepliesMultiLine,
      {
        translations: {
          getSelectXOptionsFromY: translations.getSelectXOptionsFromY
        },
        ...{
          quickReplies,
          sendMessage,
          showQuickRepliesOnMount,
          ariaId: ariaContentId
        }
      }
    ) : /* @__PURE__ */ jsx(
      QuickReplies,
      {
        translations: {
          quickRepliesAriaLabel: translations.quickRepliesAriaLabel
        },
        ...{
          quickReplies,
          sendMessage,
          showQuickRepliesOnMount,
          ariaId: ariaContentId
        }
      }
    )) })
  ] });
};
export {
  PlainTextChatMessage as default
};
