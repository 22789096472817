import { createSlice } from '@reduxjs/toolkit'
import { ScreenRecordingState } from './screenRecording.state'

const initialState: ScreenRecordingState = {
    isFullScreen: false,
}

const screenRecordingSlice = createSlice({
    name: 'screenRecording',
    initialState,
    reducers: {
        toggleFullScreen(state) {
            state.isFullScreen = !state.isFullScreen
        },
    },
    selectors: {
        selectIsFullScreen: (state: ScreenRecordingState) => {
            return state.isFullScreen
        },
    },
})

export const { toggleFullScreen } = screenRecordingSlice.actions
export const { selectIsFullScreen } = screenRecordingSlice.selectors
export default screenRecordingSlice.reducer
