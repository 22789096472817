import { createSelector } from 'reselect'

import type RootState from 'store/state'

export const selectApp = (state: RootState) => state.app

export const selectAppConfig = createSelector(selectApp, (app) => app?.appConfig)

const selectInstance = createSelector(selectApp, (app) => app?.instance)

const selectInstanceSettings = createSelector(selectInstance, (instance) => instance?.settings)

export const selectIsEnhancedMonitoringEnabled = createSelector(
    selectInstanceSettings,
    (settings) => settings?.isEnhancedMonitoringEnabled ?? false,
)

export const selectBrands = createSelector(selectApp, (app) => app?.brands)