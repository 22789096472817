const generateWidgetTitle = (
    screenRecordingURL: string | undefined,
    widgetTitle: string | undefined,
    hasAudio: boolean
) => {
    if (screenRecordingURL) {
        return hasAudio
            ? 'Screen & Call Recording'
            : 'Screen Recording'
    } else {
        return widgetTitle ?? 'Call Recording'
    }
}

export { generateWidgetTitle }
