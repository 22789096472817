import './audio-player.scss'

import { Box, Popup } from '@missionlabs/smartagent-app-components'
import useSize from 'hooks/useSize'
import React, { useEffect, useRef, useState } from 'react'
import { H } from 'react-accessible-headings'
import { useSelector } from 'react-redux'
import { ReactZoomPanPinchHandlers } from 'react-zoom-pan-pinch'
import { selectIsFullScreen } from 'store/screenRecording/screenRecording.reducer'
import { withContactConsumerContext } from 'widgets/contexts/contact'
import { Audio } from './Audio'
import { Footer } from './Footer'
import Header from './Header'
import { Seeker } from './Seeker'
import { Track } from './Track'
import VideoPlayer from './VideoPlayerSR'
import './audio-player.scss'
import { generateWidgetTitle } from './helpers/AudioPlayer'
import { IAudioPlayerProps, IWaveformChannel, VideoTransformState } from './interfaces/AudioPlayer'

export const _AudioPlayer: React.FC<IAudioPlayerProps> = ({
    refs,
    mutedState,
    signedUrl,
    hasAudio,
    waveform: _waveform,
    context,
    featureFlags,
    onAudioError,
    contactID,
    recordingDate,
    widgetTitle,
    persistentElapsed,
    setPersistentElapsed,
    screenRecordingURL,
}) => {
    // UI
    const [title, setTitle] = useState<string>('')
    const [waveformChannels, setWaveformChannels] = useState<{
        channel1: IWaveformChannel | undefined
        channel2: IWaveformChannel | undefined
    }>({ channel1: undefined, channel2: undefined })
    const { size: trackSize, ref } = useSize<HTMLDivElement>(true)

    const trackDivider = hasAudio ? 1 : 2

    // MEDIA PLAYER STATE
    const [duration, setDuration] = useState(0)
    const [playing, setPlaying] = useState(false)

    const [elapsed, setElapsed] = useState(persistentElapsed ?? 0)
    const [seekPosition, setSeekPosition] = useState<number>(0)
    const [manualSeekPosition, setManualSeekPosition] = useState<number | null>(null)
    const [isTimeUpdateActive, setIsTimeUpdateActive] = useState(true)

    // VIDEO PLAYER STATE
    const [videoZoomControls, setVideoZoomControls] = useState<ReactZoomPanPinchHandlers | null>(
        null,
    )
    const [videoTransformState, setVideoTransformState] = useState<VideoTransformState>({
        positionX: 0,
        positionY: 0,
        scale: 1,
    })
    // PROPS
    const { audioRef, videoRef, containerRef, setAudioRef, setContainerRef, setVideoRef } = refs
    const { ch1Muted, setCh1Muted, ch2Muted, setCh2Muted } = mutedState

    const isFullScreen = useSelector(selectIsFullScreen)

    /**
     * The function calculates and returns a clamped percentage based on the position of a mouse click
     * within the track element.
     * @param event - The `event` parameter is triggered when a user interacts with a `<div>` element in the UI.
     * @returns The function  returns the clamped percentage value, which is a number between 0 and 100
     * representing the percentage of the click position relative to the width of the track element.
     */
    const calculateClampedPercentage = (event: React.MouseEvent<HTMLDivElement>) => {
        const trackElement = event.currentTarget
        const rect = trackElement.getBoundingClientRect()

        const clickPositionRelative = event.clientX - rect.left
        const newProgressPercentage = (clickPositionRelative / rect.width) * 100
        const clampedPercentage = Math.max(0, Math.min(100, newProgressPercentage))
        return clampedPercentage
    }

    /**
     * The function handles seeking functionality by updating the seek position and elapsed time based on user input.
     * @param event - The `event` parameter represents the mouse event that triggered the seek action.
     * @returns The function `onSeek` is returning the `clampedPercentage` value.
     */
    const onSeek = async (event: React.MouseEvent<HTMLDivElement>) => {
        const clampedPercentage = calculateClampedPercentage(event)
        const newElapsed = (clampedPercentage / 100) * duration

        // update UI, elapsed time state and temporarily disable time updates
        // to avoid conflicts during the seek action
        setManualSeekPosition(clampedPercentage)
        setElapsed(newElapsed)
        setIsTimeUpdateActive(false)

        // update the current playback time for the audio element, if it exists.
        if (audioRef?.current) {
            audioRef.current.currentTime = newElapsed
        }

        // update the current playback time for the video element, if it exists.
        if (videoRef?.current) {
            videoRef.current.currentTime = newElapsed
        }

        // after a short delay, reset the seek position indicator and re-enable time updates
        setTimeout(() => {
            setManualSeekPosition(null)
            setIsTimeUpdateActive(true)
        }, 100)

        return clampedPercentage
    }

    const player = useRef<HTMLDivElement>(null)

    const muteChannel = (channel: number) => {
        if (channel === 1) {
            // LEFT CHANNEL
            setCh1Muted(!ch1Muted)
        } else if (channel === 2) {
            // RIGHT CHANNEL
            setCh2Muted(!ch2Muted)
        }
    }

    // USE EFFECTS

    useEffect(() => {
        let validWaveform
        if (_waveform) {
            validWaveform = JSON.parse(_waveform as unknown as string)
            let { channel1, channel2 } = validWaveform ?? {}
            if (channel1 && channel2) {
                // if video is available, do  not use waveforms
                // as they might not be aligned with actual timeline
                // prefer progress bar by setting waveform channels to undefined
                if (screenRecordingURL) {
                    channel1 = undefined
                    channel2 = undefined
                }
                setWaveformChannels({ channel1, channel2 })
            }
        }
    }, [_waveform, screenRecordingURL])

    // setting widget title based on screenRecording
    useEffect(() => {
        const newTitle = generateWidgetTitle(screenRecordingURL, widgetTitle, hasAudio)
        setTitle(newTitle)
    }, [screenRecordingURL, hasAudio])

    // sync video and audio playback
    useEffect(() => {
        if (!audioRef?.current) return

        if (playing) {
            // videoRef is optional
            videoRef?.current?.play()
            audioRef.current.play()
        } else {
            // videoRef is optional
            videoRef?.current?.pause()
            audioRef.current.pause()
        }
    }, [playing])

    // setting duration of audio element in local state once its metadata is loaded
    useEffect(() => {
        const audioElement = audioRef?.current
        const videoElement = videoRef?.current

        const handleLoadedMetadata = () => {
            const duration = videoElement?.duration || audioElement?.duration
            if (duration) {
                setDuration(duration)
            }
        }

        audioElement?.addEventListener('loadedmetadata', handleLoadedMetadata)
        videoElement?.addEventListener('loadedmetadata', handleLoadedMetadata)

        return () => {
            audioElement?.removeEventListener('loadedmetadata', handleLoadedMetadata)
            videoElement?.removeEventListener('loadedmetadata', handleLoadedMetadata)
        }
    }, [audioRef, videoRef])

    // updates the elapsed time of audio playback using requestAnimationFrame for smooth updates.
    // automatically cleans up the animation frame when component unmounts or dependencies change.
    useEffect(() => {
        const audioElement = audioRef?.current
        const videoElement = videoRef?.current
        let rafId: number

        const updateTime = () => {
            if (!audioElement) return
            let currentTime
            if (videoElement) {
                currentTime =
                    audioElement.currentTime < audioElement.duration
                        ? audioElement.currentTime
                        : videoElement.currentTime
            } else {
                currentTime = audioElement.currentTime
            }
            setElapsed(currentTime)
            rafId = requestAnimationFrame(updateTime)
        }

        rafId = requestAnimationFrame(updateTime)

        return () => {
            cancelAnimationFrame(rafId)
        }
    }, [audioRef, videoRef, isTimeUpdateActive])

    // synchronises the audio player's current time with `persistentElapsed` whenever this changes.
    // this ensures the audio player's state remains consistent when toggling fullscreen mode.
    useEffect(() => {
        if (persistentElapsed == null) return

        if (!isNaN(persistentElapsed) && isFinite(persistentElapsed)) {
            setElapsed(persistentElapsed)
            if (audioRef?.current) {
                audioRef.current.currentTime = persistentElapsed
            }
            if (videoRef?.current) {
                videoRef.current.currentTime = persistentElapsed
            }
        }
    }, [persistentElapsed, audioRef?.current, videoRef?.current])

    // synchronises the seek position and elapsed time based on user input or elapsed playback.
    // - If a manual seek position is provided, calculate the corresponding elapsed time
    // and update both the elapsed time and seek position.
    // - If no manual seek position is provided, calculate the seek position as a percentage
    //   of the elapsed time relative to the duration, ensuring the value stays within [0, 100].
    useEffect(() => {
        if (manualSeekPosition !== null) {
            const newElapsed = (manualSeekPosition / 100) * duration
            setElapsed(newElapsed)
            setSeekPosition(manualSeekPosition)
        } else {
            const newPosition = duration
                ? Math.min(100, Math.max(0, (elapsed / duration) * 100))
                : 0
            setSeekPosition(newPosition)
        }
    }, [manualSeekPosition, elapsed, duration])

    // handling case where audio is shorter than video
    // video playback continues, audio current time is clamped to end of track
    useEffect(() => {
        const audioElement = audioRef?.current
        const videoElement = videoRef?.current

        if (!audioElement || !videoElement) return

        const handleAudioEnd = () => {
            // continue playing video even if audio has ended
            videoElement.play()
            setPlaying(true)
        }

        audioElement.addEventListener('ended', handleAudioEnd)

        return () => {
            audioElement.removeEventListener('ended', handleAudioEnd)
        }
    }, [audioRef, videoRef])

    // resetting player button once audio (or video) ends
    const onMediaEnded = () => {
        setPlaying(false)
    }

    // checking if component will render audio and/or video, and then adding correct even listeners to handle media end
    useEffect(() => {
        const mediaElement = screenRecordingURL ? videoRef?.current : audioRef?.current

        if (mediaElement) {
            mediaElement.addEventListener('ended', onMediaEnded)

            return () => {
                mediaElement.removeEventListener('ended', onMediaEnded)
            }
        }
    }, [signedUrl, screenRecordingURL, onMediaEnded, videoRef, audioRef])

    // <Header /> displayed when MediaPlayer section is hidden
    // all controls should be hidden
    const hiddenHeader = (
        <div className="row middle evenly grow">
            <H>{title}</H>
        </div>
    )

    const audioPlayerComponent = (
        <Box
            className="audio-player"
            alt
            collapse
            boxLabel={title}
            {...(hiddenHeader ? { hiddenHeader } : {})}
            header={
                <Header
                    deleteScreenRecordingParams={{ contactID, recordingDate }}
                    featureFlags={featureFlags}
                    screenRecordingURL={screenRecordingURL}
                    contactID={contactID}
                    recordingDate={recordingDate}
                    signedUrl={signedUrl}
                    title={title}
                    refs={{ audioRef, containerRef, videoRef }}
                    playbackState={{
                        elapsed,
                        setElapsed,
                        playing,
                        setPlaying,
                        duration,
                        setPersistentElapsed,
                    }}
                    videoZoomControls={videoZoomControls}
                />
            }
        >
            {/* VIDEO PLAYER */}
            {screenRecordingURL && (
                <div className="sa-videoplayer screen-recording-video" ref={player}>
                    <VideoPlayer
                        isPlaying={playing}
                        seekPosition={elapsed}
                        setContainerRef={setContainerRef}
                        url={screenRecordingURL}
                        setVideoRef={setVideoRef}
                        setVideoZoomControls={setVideoZoomControls}
                        transformState={{ setVideoTransformState, videoTransformState }}
                    />
                </div>
            )}

            {/* AUDIO PLAYER */}
            <div ref={ref} data-testid="audio-player" className="sa-audioplayer">
                <Seeker to={seekPosition} onSeek={onSeek} trackDivide={trackDivider}/>
                    <Track
                        channel={waveformChannels.channel1}
                        currentTimePercentage={seekPosition}
                        currentTimeSeconds={elapsed}
                        maxTimeSeconds={duration}
                        id={1}
                        muted={ch1Muted}
                        muteChannel={muteChannel}
                        onSeek={onSeek}
                        width={trackSize.width}
                        isPlaying={playing}
                        hasAudio={hasAudio}
                    />
                    {hasAudio && 
                    <Track
                        channel={waveformChannels.channel2}
                        currentTimePercentage={seekPosition}
                        currentTimeSeconds={elapsed}
                        maxTimeSeconds={duration}
                        id={2}
                        muted={ch2Muted}
                        muteChannel={muteChannel}
                        onSeek={onSeek}
                        width={trackSize.width}
                        isPlaying={playing}
                        hasAudio={hasAudio}
                    />
                    }
                    <Footer time={duration} />
            </div>

            <Audio
                time={duration}
                url={signedUrl}
                context={context}
                stateFns={{ setCh1Muted, setCh2Muted, setPlaying, setElapsed }}
                onError={onAudioError}
                setAudioRef={setAudioRef}
            />
        </Box>
    )

    return isFullScreen ? (
        <Popup id="sa-audioplayer-popup" center>
            {audioPlayerComponent}
        </Popup>
    ) : (
        audioPlayerComponent
    )
}

export const AudioPlayer = withContactConsumerContext(_AudioPlayer)
