import { Box, Button } from '@missionlabs/smartagent-app-components'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { ClipLoader } from 'react-spinners'
import useContact from 'hooks/redux/useContact'
import './recordingcontrol.scss'
import useRecordingControl, { RecordingTypes } from './useRecordingControl'
import useSettings from 'hooks/redux/useSettings'
import { NOTIFICATION_TIMEOUT_IN_MILLISECONDS } from 'store/middleware/ccp/ccp.constants'
import { createNotification } from 'store/notification/notification.actions'
import { useDispatch } from 'react-redux'

const RecordingControl: React.FC = () => {
    const { loading, toggleRecording, recordingStatus, initialRecordingType, hideRecordingControl } = useRecordingControl();
    const contact = useContact();
    const dispatch = useDispatch();
    const { settings } = useSettings();

    const showRecordingNotification =
        settings?.appConfigurations?.general?.showRecordingNotification ?? true;
    const callRecordingDisplayMode: string | undefined =
        contact?.attributes?.['sa-recording-button'];

    const shouldDisplayRecordingControl =
        callRecordingDisplayMode === 'SHOW' && !hideRecordingControl;
    console.log({ hideRecordingControl})
    useEffect(() => {
        if (showRecordingNotification && initialRecordingType !== RecordingTypes.NONE) {
            dispatch(
                createNotification({
                    header: 'Information',
                    type: 'info',
                    text: `${initialRecordingType} recording has been enabled.`,
                    closeAfterMs: NOTIFICATION_TIMEOUT_IN_MILLISECONDS,
                }),
            );
        }
    }, [showRecordingNotification, initialRecordingType]);

    const createControlText = () => {
        const verb = !recordingStatus ? 'Start' : (recordingStatus === 'off' ? 'Resume' : 'Pause');
        // Edge case: If no initial recording type has been set
        // then indicate that pressing the button will only start call recording.
        const type = initialRecordingType === RecordingTypes.NONE ? RecordingTypes.CALL : initialRecordingType;
        return `${verb} ${type} recording`;
    }
    const controlText = createControlText();
    
    const classes = classNames({
        'sa-callrecordingcontrol row': true,
        on: shouldDisplayRecordingControl && recordingStatus === 'on',
    });

    return (
        <>
            {shouldDisplayRecordingControl && (
                <Box>
                    <Button className={classes} onClick={toggleRecording}>
                        <div className="row">
                            <div className="sa-callrecordingcontrol-status">&nbsp;</div>
                            <span>
                                {controlText}
                            </span>
                        </div>
                        {loading && <ClipLoader size={14} color={'#fff'} />}
                    </Button>
                </Box>
            )}
        </>
    )
}

export default RecordingControl
