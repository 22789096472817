import './task-view.scss'

import { EmailMessages, TaskAudit } from 'components'
import React, { useEffect, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import {
    fetchAttachmentUrls,
    fetchAutomatedRulesAudit,
    fetchQueuedTasksAudit,
} from 'store/contactSearch/contactSearch.actions'
import { ContactDetails } from 'widgets/ContactDetails'
import { CallRecording } from 'widgets/CallRecording'

import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'

import { Box } from '@missionlabs/smartagent-app-components'
import { H, Level } from 'react-accessible-headings'

import { IInteraction } from '../../types'
import { attachmentsRequireTransformation } from '../../utils'

export interface Props {
    interaction: IInteraction
}

const TaskView: React.FC<Props> = ({ interaction }) => {
    const hasFeature = useHasFeature()
    const dispatch = useDispatch()

    const {
        ID: interactionID,
        emailMessages,
        queuedTasksAudit,
        automatedRulesAudit,
        messageId,
        hasAutoReply = false,
        attributes: { 'sa-threadID': threadId },
        attributesUpdated = false,
        actionAuditId,
    } = interaction

    useEffect(() => {
        batch(() => {
            // Temporary batch until migration to React 18
            if (!queuedTasksAudit) dispatch(fetchQueuedTasksAudit(interactionID))
            if (!automatedRulesAudit)
                dispatch(fetchAutomatedRulesAudit(interactionID, actionAuditId))
        })
    }, [])

    emailMessages?.forEach(({ attachments, messageId }) => {
        if (attachmentsRequireTransformation(attachments)) {
            dispatch(fetchAttachmentUrls(attachments, messageId, threadId))
        }
    })

    const [viewPreviousEmailMessages, setViewPreviousEmailMessages] = useState<boolean>(false)

    return (
        <div className="task-view-container">
            <div className="task-view-container-left">
                <ContactDetails
                    contact={attributesUpdated ? interaction : undefined}
                    widgetTitle="Additional info"
                />
                {hasFeature(AppFeatures.CALL_RECORDING) && (
                        <CallRecording
                            key={interaction.ID}
                            contactID={interaction.ID}
                            recordingDate={interaction.initiationTimestamp}
                            widgetTitle="Screen Recording"
                        />
                )}
                {emailMessages && (
                    <TaskAudit
                        automatedRulesAudit={automatedRulesAudit}
                        queuedTasksAudit={queuedTasksAudit}
                        hasAutoReply={hasAutoReply}
                    />
                )}
            </div>
            {emailMessages && (
                <div className="task-view-container-right">
                    <Box
                        className="task-view-email-content"
                        alt
                        collapse
                        header={<H>Email Content</H>}
                        boxLabel="Email Content"
                    >
                        <Level>
                            <EmailMessages
                                emailMessages={emailMessages}
                                highlightedMessageId={messageId}
                                viewPreviousEmailMessages={viewPreviousEmailMessages}
                                setViewPreviousEmailMessages={() => setViewPreviousEmailMessages(true)}
                            />
                        </Level>
                    </Box>
                </div>
            )}
        </div>
    )
}

export default TaskView