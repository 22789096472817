import { Button } from '@missionlabs/smartagent-app-components'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CallState from 'store/call/call.state'
import { IChat } from 'store/chat/chat.state'
import { ITask } from 'store/tasks/tasks.state'

import classNames from 'classnames'
import useApp from 'hooks/redux/useApp'
import useChatConnections from 'hooks/redux/useChatConnections'
import useUser from 'hooks/redux/useUser'
import useGetDirectoryContact from 'hooks/useGetDirectoryContact'
import AcceptContact from 'images/accept-contact.svg'
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg'
import Cross from 'images/cross.svg'
import { ReactComponent as PhoneIcon } from 'images/phone.svg'
import { ReactComponent as TaskIcon } from 'images/task-icon.svg'
import logger from 'logger'
import { ClipLoader } from 'react-spinners'
import { acceptCall, rejectCall } from 'store/call/call.actions'
import { acceptChat, declineChat, setSelectedChat } from 'store/chat/chat.actions'
import { selectContactSubType } from 'store/contact/contact.selectors'
import { setRedirect } from 'store/global/global.actions'
import { acceptTask, declineTask } from 'store/tasks/tasks.actions'

interface Props {
    active?:
        | { channel: 'VOICE'; call: CallState }
        | { channel: 'CHAT'; chat: IChat }
        | { channel: 'TASK'; task: ITask }
}

const getDescription = (queueName?: string) => queueName ?? 'Inbound'

export const IncomingChat: React.FC<IChat> = ({
    customerName,
    id,
    queueName,
    channel = 'chat',
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()

    const chats = useChatConnections()
    const hasActiveChat = chats.find((c) => c.status === connect.ContactStateType.CONNECTED)

    const accept = () => {
        ref?.current?.setAttribute('aria-hidden', 'true')

        dispatch(acceptChat(id))

        if (!hasActiveChat) {
            dispatch(setSelectedChat(id))
        }
    }

    const decline = () => {
        dispatch(declineChat(id))
    }

    const chatDisplayDescription = getDescription(queueName)

    return (
        <div
            ref={ref}
            className="sa-incoming-contact-wrapper"
            role="alert"
            aria-label={`Incoming ${channel} contact, from ${customerName}, ${chatDisplayDescription}`}
        >
            <div className="sa-incoming-contact">
                <div className="sa-incoming-contact-details-wrapper">
                    <ChatIcon title="Chat" data-testid='incoming-contact-chat-icon' height={24} width={24} />
                    <div className="sa-incoming-contact-details" aria-hidden={true}>
                        <span title={customerName} className="sa-incoming-contact-details-title">
                            {customerName}
                        </span>
                        <p
                            title={chatDisplayDescription}
                            className="sa-incoming-contact-details-description"
                        >
                            {chatDisplayDescription}
                        </p>
                    </div>
                </div>
                <Button aria-label="Accept" className="sa-incoming-answer" onClick={accept}>
                    <img src={AcceptContact} alt="Accept" />
                </Button>
                <Button aria-label="Reject" className="sa-incoming-decline" onClick={decline}>
                    <img src={Cross} alt="Reject" />
                </Button>
            </div>
        </div>
    )
}

export const IncomingTask: React.FC<ITask> = ({
    id,
    channel = 'Task',
    accepting,
    name,
    queueName,
    attributes,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    const accept = () => {
        ref?.current?.setAttribute('aria-hidden', 'true')
        dispatch(acceptTask(id))
    }

    const decline = () => {
        dispatch(declineTask(id))
    }

    const taskDisplayName = attributes?.['sa-task-display-name']?.value || name
    const taskDisplayDescription =
        attributes?.['sa-task-display-description']?.value || getDescription(queueName)

    return (
        <div
            ref={ref}
            className="sa-incoming-contact-wrapper"
            role="alert"
            aria-label={`Incoming ${channel} contact, from ${taskDisplayName}, ${taskDisplayDescription}`}
        >
            <div className="sa-incoming-contact">
                <div className="sa-incoming-contact-details-wrapper" aria-hidden={true}>
                    <TaskIcon title="Task" data-testid='incoming-contact-task-icon' height={24} width={24} />
                    <div className="sa-incoming-contact-details">
                        <span title={taskDisplayName} className="sa-incoming-contact-details-title">
                            {taskDisplayName}
                        </span>
                        <p
                            title={taskDisplayDescription}
                            className="sa-incoming-contact-details-description"
                        >
                            {taskDisplayDescription}
                        </p>
                    </div>
                </div>
                <Button aria-label="Accept" className="sa-incoming-answer" onClick={accept}>
                    {accepting ? (
                        <ClipLoader size={16} color="#fff" />
                    ) : (
                        <img src={AcceptContact} alt="Accept" />
                    )}
                </Button>
                <Button aria-label="Reject" className="sa-incoming-decline" onClick={decline}>
                    <img src={Cross} alt="Reject" />
                </Button>
            </div>
        </div>
    )
}

export const IncomingVoice: React.FC<CallState> = ({ number, queueName }) => {
    const ref = useRef<HTMLDivElement>(null)

    const dispatch = useDispatch()

    const { softphoneEnabled, softphoneAutoAccept } = useUser() ?? {}

    const contactSubType = useSelector(selectContactSubType)
    const isWebCall = contactSubType === 'connect:WebRTC'

    const [, name] = useGetDirectoryContact(number)

    // thameswater prod debugging
    const { ID } = useApp()
    useEffect(() => {
        if (ID === 'tw' && !softphoneEnabled) {
            logger.warning('Thames Water Softphone Disabled UI Alert')
        }
    }, [ID, softphoneEnabled])

    useEffect(() => {
        if (softphoneAutoAccept) dispatch(acceptCall())
    }, [softphoneAutoAccept])

    const answer = () => {
        ref?.current?.setAttribute('aria-hidden', 'true')

        dispatch(acceptCall())
        dispatch(setRedirect('/'))
    }

    const decline = () => {
        dispatch(rejectCall())
    }

    const acceptButtonClassName = classNames('sa-incoming-answer', {
        'sa-incoming-answer--last-button': softphoneAutoAccept,
    })

    const voiceDisplayName = name?.trim() || number
    const voiceDisplayDescription = getDescription(queueName)

    return (
        <div
            ref={ref}
            className="sa-incoming-contact-wrapper"
            role="alert"
            aria-label={`Incoming voice contact, from ${voiceDisplayName}, ${voiceDisplayDescription}`}
        >
            <div className="sa-incoming-contact">
                <div className="sa-incoming-contact-details-wrapper">
                    <PhoneIcon title="Phone" data-testid='incoming-contact-voice-icon' height={24} width={24} />
                    <div className="sa-incoming-contact-details" aria-hidden={true}>
                        {isWebCall && (
                            <span className="sa-incoming-contact-details-title">Web call</span>
                        )}
                        <span
                            title={voiceDisplayName}
                            className="sa-incoming-contact-details-title"
                        >
                            {voiceDisplayName}
                        </span>
                        <p
                            title={voiceDisplayDescription}
                            className="sa-incoming-contact-details-description"
                        >
                            {voiceDisplayDescription}
                        </p>
                    </div>
                </div>
                {softphoneEnabled ? (
                    <>
                        <Button title="Accept" className={acceptButtonClassName} onClick={answer}>
                            <img src={AcceptContact} alt="Accept" />
                        </Button>
                        {!softphoneAutoAccept && (
                            <Button
                                title="Reject"
                                className="sa-incoming-decline"
                                onClick={decline}
                            >
                                <img src={Cross} alt="Reject" />
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        <Button title="Reject" className="sa-incoming-decline" onClick={decline}>
                            <img src={Cross} alt="Reject" />
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

const IncomingContact: React.FC<Props> = ({ active }) => {
    if (active?.channel === 'VOICE') return <IncomingVoice {...active.call} />
    if (active?.channel === 'CHAT') return <IncomingChat {...active.chat} />
    if (active?.channel === 'TASK') return <IncomingTask {...active.task} />
    return null
}

export default IncomingContact
