import { f, F } from "./index-BWKHyknh.mjs";
import { default as default2 } from "./AlertBanner.mjs";
import { default as default3 } from "./AttachedFile.mjs";
import { default as default4 } from "./AudioPlayer.mjs";
import { default as default5 } from "./Box.mjs";
import { default as default6 } from "./Button.mjs";
import { default as default7 } from "./Card.mjs";
import { default as default8 } from "./CheckBoxGroup.mjs";
import { default as default9 } from "./Checkbox.mjs";
import { default as default10 } from "./CheckboxPicker.mjs";
import { default as default11 } from "./Collapsible.mjs";
import { default as default12 } from "./ConditionalWrapper.mjs";
import { default as default13 } from "./CopyClipboard.mjs";
import { default as default14 } from "./DataDisplay.mjs";
import { default as default15 } from "./DataField.mjs";
import { default as default16 } from "./DatePicker.mjs";
import { default as default17 } from "./Dot.mjs";
import { default as default18 } from "./Form.mjs";
import { default as default19 } from "./Input.mjs";
import { u as userLoaded, a as userAuthenticated, b as userAuthError, c as authenticatingUser, v as validatingToken, r as requestingToken, m as mfaRequired, t as tokenRequested, d as tokenError, l as login, e as logout, f as ccpLogout, g as userLoaded$1, h as requestToken, i as refreshToken, j as userError, s as selectInstance, k as authExternalUserRequest, n as setCallProviderInitStartTime, o as authError, p as submitMFA, q as mfaRequired$1, w as authenticateUser, x as initAppSuccess, y as appReducer, z as userReducer, A as globalReducer } from "./index-B1FQZMTT.mjs";
import { L, B } from "./index-B1FQZMTT.mjs";
import { default as default20 } from "./Message.mjs";
import { default as default21 } from "./MultiStateCheckbox.mjs";
import { default as default22 } from "./Notification.mjs";
import { P, S } from "./index-BLC3l8Sk.mjs";
import { default as default23 } from "./Popup.mjs";
import { default as default24 } from "./Radio.mjs";
import { default as default25 } from "./RadioGroup.mjs";
import { default as default26 } from "./RadioPicker.mjs";
import { default as default27 } from "./ReactDatePicker.mjs";
import { default as default28 } from "./SectionHeading.mjs";
import { default as default29 } from "./Slideout.mjs";
import { default as default30 } from "./Table.mjs";
import { Tab, default as default31 } from "./Tabs.mjs";
import { default as default32 } from "./Tag.mjs";
import { default as default33 } from "./TimeCountdown.mjs";
import { default as default34 } from "./TimeElapsed.mjs";
import { default as default35 } from "./ToggleSwitch.mjs";
import { default as default36 } from "./Tooltip.mjs";
import { default as default37 } from "./TriggeredPopup.mjs";
import { default as default38 } from "./TypingIndicator.mjs";
import { default as default39 } from "./VideoPlayer.mjs";
import { WYSIWYG } from "./WYSIWYG.mjs";
import { createSlice, configureStore as configureStore$1, combineReducers } from "@reduxjs/toolkit";
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { UserAgentApplication } from "msal";
import { b as getParameterByName, d as getHashParameterByName } from "./utils-CO0rFpS6.mjs";
import { jsx } from "react/jsx-runtime";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { f as f2 } from "./date-time-C9VIKgtX.mjs";
import { EditorState } from "draft-js";
const initialState = {
  authenticated: false,
  logout: false
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLoaded, (state) => {
      return { ...state, logout: false };
    }).addCase(userAuthenticated, (_, action) => {
      return { authenticated: true, ...action.payload };
    }).addCase(userAuthError, (state, action) => {
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        authError: action.payload.error
      };
    }).addCase(authenticatingUser, (state, action) => {
      return { ...state, authenticating: true };
    }).addCase(validatingToken, (state) => {
      return {
        roles: state.roles,
        features: state.features,
        token: state.token,
        refreshToken: state.refreshToken,
        validatingToken: true,
        authenticated: state.authenticated
      };
    }).addCase(requestingToken, () => {
      return { requestingToken: true };
    }).addCase(mfaRequired, (_, action) => {
      return { mfa: action.payload };
    }).addCase(tokenRequested, () => {
      return { tokenRequested: true };
    }).addCase(tokenError, () => {
      return { tokenError: true };
    }).addCase(login, (state) => {
      return { ...state, logout: false, authError: "" };
    }).addCase(logout, () => {
      return { authenticated: false, logout: true, token: void 0 };
    }).addCase(ccpLogout, () => {
      return { authenticated: false, logout: true, token: void 0 };
    });
  }
});
const authReducer = authSlice.reducer;
const listenToAgent = (store) => {
  let sharedAgent;
  connect.agent((agent) => {
    sharedAgent = agent;
    window.agent = sharedAgent;
    store.dispatch(
      userLoaded$1({
        agentID: store.getState().auth.agentID,
        name: agent.getName(),
        username: agent.getConfiguration().username
      })
    );
    const auth = store.getState().auth;
    const token = auth.refreshToken;
    const { identityManagement } = store.getState().app;
    if (!identityManagement || identityManagement === "connect") {
      if (!token) {
        store.dispatch(requestToken());
        window.postMessage({ type: "READ_TOKEN" }, "*");
      } else if (!auth.authenticated) {
        store.dispatch(refreshToken());
      }
    }
    agent.onError((agent2) => {
      console.log("Agent transferred into an error state");
      const state = agent2.getState();
      console.log("Agent in state:", state);
      if (state.name === connect.AgentErrorStates.MISSED_CALL_AGENT)
        return;
      store.dispatch(userError());
    });
    agent.onRefresh((agent2) => {
      store.dispatch(
        userLoaded$1({
          agentID: store.getState().auth.agentID,
          name: agent2.getName(),
          username: agent2.getConfiguration().username
        })
      );
    });
  });
  return () => sharedAgent;
};
const ccpAgentMiddleware = (store) => (next) => async (action) => {
  switch (action.type) {
    case selectInstance.type:
      listenToAgent(store);
      return next(action);
    default:
      return next(action);
  }
};
const CCP_INIT_TIMEOUT_MS = 6e4;
window.connect = connect;
let w;
const getCCPURL = (ccpURL) => !ccpURL.match(/connect\/ccp-v2/g) ? ccpURL.replace(/connect\/ccp/g, "connect/ccp-v2") : ccpURL;
function openCCPWindow(ccpURL) {
  const getTop = () => window.innerHeight / 2 - 290;
  const getLeft = () => window.innerWidth / 2 - 200;
  w = window.open(
    getCCPURL(ccpURL),
    "ccp",
    `toolbar=no,height=600,width=400,top=${getTop() + window.screenY},left=${getLeft() + window.screenX}`
  );
  if (w) {
    w.focus();
  }
}
const getCCPIframeSrc = (ccpURL) => {
  if (ccpURL.includes("awsapps")) {
    return ccpURL.replace(
      /\/connect(.*)$/,
      "/connect/request-storage-access"
    );
  }
  return ccpURL.replace(/.aws\/(.*)$/, ".aws/request-storage-access");
};
const ccpAppMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case selectInstance.type: {
      console.log("action.payload", action.payload);
      const { instance, appConfig } = action.payload;
      const app = document.getElementById("app");
      const ccpUrl = getCCPURL(instance.ccpURL);
      console.log("connect.core", connect.core);
      connect.core.initCCP(app, {
        ccpUrl,
        loginPopup: false,
        region: instance.region,
        softphone: {
          allowFramedSoftphone: appConfig.allowFramedSoftphone ? true : false
        },
        //@ts-ignore
        ccpAckTimeout: appConfig.ccpAckTimeout,
        //optional, defaults to 3000 (ms)
        //@ts-ignore
        ccpSynTimeout: appConfig.ccpSynTimeout,
        //optional, defaults to 1000 (ms)
        //@ts-ignore
        ccpLoadTimeout: appConfig.ccpLoadTimeout
        //optional, defaults to 5000 (ms)
      });
      store.dispatch(setCallProviderInitStartTime(Date.now()));
      connect.core.initSoftphoneManager({
        allowFramedSoftphone: true
      });
      connect.core.onAccessDenied(function() {
        if (store.getState().auth.authenticated && !store.getState().user) {
          store.dispatch(
            authError(
              "User does not have correct permissions"
            )
          );
        }
      });
      connect.core.onAuthFail(() => {
        store.dispatch(ccpLogout());
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
      return next(action);
    }
    case authExternalUserRequest.type: {
      const iframe = document.getElementById(
        "sa-login-iframe"
      );
      const { callProviderInitStartTime } = store.getState().app;
      if (callProviderInitStartTime) {
        const currentTime = Date.now();
        if (currentTime - callProviderInitStartTime > CCP_INIT_TIMEOUT_MS) {
          return window.location.reload();
        }
      }
      try {
        console.log("smartagent-login", {
          type: "smartagent-login",
          credentials: action.payload.credentials,
          ccpURL: getCCPURL(action.payload.ccpURL)
        });
        iframe.contentWindow.postMessage(
          {
            type: "smartagent-login",
            credentials: action.payload.credentials,
            ccpURL: getCCPURL(action.payload.ccpURL)
          },
          "*"
        );
      } catch (error) {
        console.log("Error posting init: ", error);
      }
      return next(action);
    }
    case login.type: {
      const { instance, identityManagement } = store.getState().app;
      if (!instance)
        return next(action);
      if (identityManagement && identityManagement !== "connect") {
        return next(action);
      }
      w ? w.focus() : openCCPWindow(instance.ccpURL);
      return next(action);
    }
    case logout.type: {
      const eventBus = connect.core.getEventBus();
      eventBus.trigger(connect.EventType.TERMINATED);
      connect.core.terminate();
      const url = store.getState().app.instance.ccpURL;
      const iframe = document.querySelector(
        `iframe[src="${getCCPIframeSrc(url)}"]`
      );
      const connectURL = new URL(url);
      connectURL.pathname = "/connect/logout";
      const logoutUrl = connectURL.toString();
      iframe.src = logoutUrl;
      setTimeout(() => {
        window.location.reload();
      }, 500);
      return next(action);
    }
    case userLoaded.type:
      w == null ? void 0 : w.close();
      return next(action);
    default:
      return next(action);
  }
};
const ccp = [ccpAppMiddleware, ccpAgentMiddleware];
const urlparams = new URLSearchParams(window.location.hash.replace("#", ""));
let user;
const getCurrentUserToken = async (cognito) => {
  if (cognito.type !== "in_app")
    return;
  const pool = new CognitoUserPool({
    ClientId: cognito.client_id,
    UserPoolId: cognito.userPoolId
  });
  user = pool.getCurrentUser();
  if (!user)
    return;
  try {
    const session = await getUserSession(user);
    if (!session.isValid())
      return;
    const token = session.getAccessToken().getJwtToken();
    const idToken = session.getIdToken().getJwtToken();
    localStorage.setItem("oauthToken", idToken);
    return token;
  } catch (ex) {
    return;
  }
};
const getUserSession = (user2) => {
  return new Promise((resolve, reject) => {
    user2.getSession((err, session) => {
      if (err)
        return reject();
      return resolve(session);
    });
  });
};
const getCognitoUser = (username, cognito) => {
  if (cognito.type === "in_app") {
    const pool = new CognitoUserPool({
      ClientId: cognito.client_id,
      UserPoolId: cognito.userPoolId
    });
    return new CognitoUser({
      Username: username,
      Pool: pool
    });
  }
  return null;
};
function isCognito(identityManagement) {
  return identityManagement === "cognito";
}
const cognitoMiddleware = (store) => (next) => async (action) => {
  const { cognito, identityManagement } = store.getState().app;
  switch (action.type) {
    case initAppSuccess.type: {
      const { identityManagement: identityManagement2, cognito: cognito2 } = action.payload;
      if (!isCognito(identityManagement2) || !cognito2)
        return next(action);
      next(action);
      if (cognito2.type === "redirect") {
        const token = urlparams.get("access_token");
        if (token) {
          store.dispatch(authenticateUser(token, true));
          window.location.hash = "";
        }
      } else if (cognito2.type === "in_app") {
        getCurrentUserToken(cognito2).then((token) => {
          if (token) {
            store.dispatch(authenticateUser(token, true));
          }
        });
      }
      return;
    }
    case login.type: {
      const token = urlparams.get("access_token");
      if (isCognito(identityManagement) && cognito && !token) {
        console.log("logging in as cognito");
        if (action.payload && cognito.type === "in_app" && action.payload.password && action.payload.username) {
          store.dispatch(authenticatingUser());
          const auth = new AuthenticationDetails({
            Username: action.payload.username,
            Password: action.payload.password
          });
          user = getCognitoUser(action.payload.username, cognito);
          user && user.authenticateUser(auth, {
            mfaRequired: (challangeName, challengeParameters) => {
              console.log(challangeName, challengeParameters);
              store.dispatch(
                mfaRequired$1({
                  type: challengeParameters.CODE_DELIVERY_DELIVERY_MEDIUM,
                  destination: challengeParameters.CODE_DELIVERY_DESTINATION
                })
              );
            },
            onSuccess: (result) => {
              const token2 = result.getAccessToken();
              localStorage.setItem(
                "oauthToken",
                result.getIdToken().getJwtToken()
              );
              store.dispatch(authenticateUser(token2.getJwtToken(), true));
            },
            onFailure: (err) => {
              store.dispatch(
                authError(
                  // dont pass error message for security
                  "Incorrect username or password"
                )
              );
            }
          });
        } else if (cognito.type === "redirect") {
          window.location.href = `${cognito.domain}/login?response_type=token&client_id=${cognito.client_id}&redirect_uri=${window.location.href}&scope=openid+profile+aws.cognito.signin.user.admin`;
        }
      }
      return next(action);
    }
    case submitMFA.type:
      if (identityManagement === "cognito" && cognito && cognito.type === "in_app") {
        store.dispatch(authenticatingUser());
        user && user.sendMFACode(action.payload, {
          onSuccess: (result) => {
            const token = result.getAccessToken();
            store.dispatch(authenticateUser(token.getJwtToken(), true));
          },
          onFailure: (err) => {
            store.dispatch(authError(err.message));
          }
        });
      }
      return next(action);
    case ccpLogout.type:
    case logout.type:
      next(action);
      if (identityManagement === "cognito" && cognito && cognito.type === "redirect") {
        window.open(
          `${cognito.domain}/logout?client_id=${cognito.client_id}&logout_uri=${cognito.logoutUrl}`,
          "_blank"
        );
      }
      if (user) {
        user.signOut();
      }
      return;
    default:
      return next(action);
  }
};
let msal;
const initMSAL = async (config) => {
  const msalConfig = {
    auth: {
      redirectUri: getRedirectUri(),
      ...config.auth
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  };
  if (process.env.NODE_ENV !== "production") {
    msalConfig.auth.redirectUri = getRedirectUri();
  }
  msal = new UserAgentApplication(msalConfig);
};
const getRedirectUri = () => {
  const { protocol, hostname, search, port } = window.location;
  return protocol + "//" + hostname + `${port ? `:${port}` : ""}` + search;
};
function isActiveDirectory(identityManagement) {
  return identityManagement === "ad";
}
const adMiddleware = (store) => (next) => async (action) => {
  var _a;
  switch (action.type) {
    case initAppSuccess.type: {
      const { payload } = action;
      if (isActiveDirectory(payload.identityManagement) && ((_a = payload.ad) == null ? void 0 : _a.type) !== "openid") {
        initMSAL(payload.ad);
      }
      return next(action);
    }
    case login.type: {
      const identityManagement = store.getState().app.identityManagement;
      if (!isActiveDirectory(identityManagement)) {
        return next(action);
      }
      next(action);
      const { ad } = store.getState().app;
      if (!ad) {
        return store.dispatch(
          authError(
            "There is a problem with the active directory setup, please contact your administrator"
          )
        );
      }
      if (ad.type === "code") {
        const code = getParameterByName("code");
        const error = getParameterByName("error");
        if (code) {
          store.dispatch(authenticateUser(code, true));
          window.history.replaceState(null, "", ad.auth.redirectUri || getRedirectUri());
        } else if (error) {
          store.dispatch(
            authError(
              getParameterByName("error_description") || "Oops something went wrong"
            )
          );
        } else {
          const url = ad.auth.authority + `/oauth2/authorize?
                    client_id=${ad.auth.clientId}&
                    response_type=code&
                    redirect_uri=${ad.auth.redirectUri || getRedirectUri()}&
                    response_mode=query&resource=https://graph.microsoft.com`;
          window.location.href = url;
        }
        return;
      } else if (ad.type === "openid") {
        const code = getHashParameterByName("code");
        const error = getHashParameterByName("error");
        if (code) {
          store.dispatch(authenticateUser(code, true));
          window.history.replaceState(null, "", ad.auth.redirectUri || getRedirectUri());
        } else if (error) {
          store.dispatch(
            authError(
              getHashParameterByName("error_description") || "Oops something went wrong"
            )
          );
        } else {
          const url = ad.auth.authority + `/oauth2/v2.0/authorize?scope=openid&client_id=${ad.auth.clientId}&response_type=code&redirect_uri=${ad.auth.redirectUri || getRedirectUri()}&response_mode=fragment`;
          window.location.href = url;
        }
        return;
      }
      const requestObj = {
        scopes: ["user.read"]
      };
      if (!msal) {
        console.log("no msal");
        return;
      }
      if (msal.getAccount()) {
        try {
          const token = await msal.acquireTokenSilent(requestObj);
          return store.dispatch(authenticateUser(token.accessToken, true));
        } catch (ex) {
          console.log("not logged in so show popup");
        }
      }
      try {
        if (msal.getLoginInProgress()) {
          console.log("already in progress");
          return;
        }
        await msal.loginPopup(requestObj);
        const token = await msal.acquireTokenSilent(requestObj);
        return store.dispatch(authenticateUser(token.accessToken, true));
      } catch (ex) {
        if (ex.errorCode === "user_cancelled") {
          return store.dispatch(authError("User cancelled login"));
        }
        console.log("error from ad", ex);
        return store.dispatch(
          authError(
            "There is a problem with the active directory setup, please contact your administrator"
          )
        );
      }
    }
    default:
      return next(action);
  }
};
const middlewares$1 = [
  ...ccp,
  adMiddleware,
  cognitoMiddleware
];
const rootReducer = {
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  global: globalReducer
};
function createStore(initialState2) {
  const store = configureStore$1({
    preloadedState: initialState2,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(middlewares$1),
    devTools: { serialize: true },
    reducer: combineReducers(rootReducer)
  });
  store.subscribe(() => {
    var _a;
    if ((_a = store.getState().app) == null ? void 0 : _a.error)
      return;
    const { auth } = store.getState();
    const state = {
      auth: {
        logout: auth.logout,
        refreshToken: auth.refreshToken
      }
    };
    localStorage.setItem("state", JSON.stringify(state));
  });
  return store;
}
const RootState = {};
var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
var freeSelf = typeof self == "object" && self && self.Object === Object && self;
freeGlobal || freeSelf || Function("return this")();
var funcProto = Function.prototype;
var funcToString = funcProto.toString;
funcToString.call(Object);
function symbolObservablePonyfill(root) {
  var result;
  var Symbol2 = root.Symbol;
  if (typeof Symbol2 === "function") {
    if (Symbol2.observable) {
      result = Symbol2.observable;
    } else {
      result = Symbol2("observable");
      Symbol2.observable = result;
    }
  } else {
    result = "@@observable";
  }
  return result;
}
var root$2;
if (typeof self !== "undefined") {
  root$2 = self;
} else if (typeof window !== "undefined") {
  root$2 = window;
} else if (typeof global !== "undefined") {
  root$2 = global;
} else if (typeof module !== "undefined") {
  root$2 = module;
} else {
  root$2 = Function("return this")();
}
symbolObservablePonyfill(root$2);
function warning(message) {
  if (typeof console !== "undefined" && typeof console.error === "function") {
    console.error(message);
  }
  try {
    throw new Error(message);
  } catch (e) {
  }
}
function compose() {
  for (var _len = arguments.length, funcs = Array(_len), _key = 0; _key < _len; _key++) {
    funcs[_key] = arguments[_key];
  }
  if (funcs.length === 0) {
    return function(arg) {
      return arg;
    };
  }
  if (funcs.length === 1) {
    return funcs[0];
  }
  return funcs.reduce(function(a, b) {
    return function() {
      return a(b.apply(void 0, arguments));
    };
  });
}
var _extends = Object.assign || function(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }
  return target;
};
function applyMiddleware() {
  for (var _len = arguments.length, middlewares2 = Array(_len), _key = 0; _key < _len; _key++) {
    middlewares2[_key] = arguments[_key];
  }
  return function(createStore2) {
    return function(reducer, preloadedState, enhancer) {
      var store = createStore2(reducer, preloadedState, enhancer);
      var _dispatch = store.dispatch;
      var chain = [];
      var middlewareAPI = {
        getState: store.getState,
        dispatch: function dispatch(action) {
          return _dispatch(action);
        }
      };
      chain = middlewares2.map(function(middleware) {
        return middleware(middlewareAPI);
      });
      _dispatch = compose.apply(void 0, chain)(store.dispatch);
      return _extends({}, store, {
        dispatch: _dispatch
      });
    };
  };
}
function isCrushed() {
}
if (typeof isCrushed.name === "string" && isCrushed.name !== "isCrushed") {
  warning("You are currently using minified code outside of NODE_ENV === 'production'. This means that you are running a slower development build of Redux. You can use loose-envify (https://github.com/zertosh/loose-envify) for browserify or DefinePlugin for webpack (http://stackoverflow.com/questions/30030031) to ensure you have the correct code for your production build.");
}
var objectTag$1 = "[object Object]";
function isHostObject(value) {
  var result = false;
  if (value != null && typeof value.toString != "function") {
    try {
      result = !!(value + "");
    } catch (e) {
    }
  }
  return result;
}
function overArg$2(func, transform) {
  return function(arg) {
    return func(transform(arg));
  };
}
var funcProto$1 = Function.prototype;
var objectProto$3 = Object.prototype;
var funcToString$1 = funcProto$1.toString;
var hasOwnProperty$2 = objectProto$3.hasOwnProperty;
var objectCtorString$1 = funcToString$1.call(Object);
var objectToString$2 = objectProto$3.toString;
var getPrototype$2 = overArg$2(Object.getPrototypeOf, Object);
function isObjectLike$2(value) {
  return !!value && typeof value == "object";
}
function isPlainObject$2(value) {
  if (!isObjectLike$2(value) || objectToString$2.call(value) != objectTag$1 || isHostObject(value)) {
    return false;
  }
  var proto = getPrototype$2(value);
  if (proto === null) {
    return true;
  }
  var Ctor = hasOwnProperty$2.call(proto, "constructor") && proto.constructor;
  return typeof Ctor == "function" && Ctor instanceof Ctor && funcToString$1.call(Ctor) == objectCtorString$1;
}
var lodash_isplainobject = isPlainObject$2;
var toConsumableArray = function(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++)
      arr2[i] = arr[i];
    return arr2;
  } else {
    return Array.from(arr);
  }
};
var isFunction = function isFunction2(arg) {
  return typeof arg === "function";
};
function configureStore() {
  var middlewares2 = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
  return function mockStore2() {
    var _getState = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    function mockStoreWithoutMiddleware() {
      var actions = [];
      var listeners = [];
      var self2 = {
        getState: function getState() {
          return isFunction(_getState) ? _getState(actions) : _getState;
        },
        getActions: function getActions() {
          return actions;
        },
        dispatch: function dispatch(action) {
          if (!lodash_isplainobject(action)) {
            throw new Error("Actions must be plain objects. Use custom middleware for async actions.");
          }
          if (typeof action.type === "undefined") {
            throw new Error('Actions may not have an undefined "type" property. Have you misspelled a constant? Action: ' + JSON.stringify(action));
          }
          actions.push(action);
          for (var i = 0; i < listeners.length; i++) {
            listeners[i]();
          }
          return action;
        },
        clearActions: function clearActions() {
          actions = [];
        },
        subscribe: function subscribe(cb) {
          if (isFunction(cb)) {
            listeners.push(cb);
          }
          return function() {
            var index = listeners.indexOf(cb);
            if (index < 0) {
              return;
            }
            listeners.splice(index, 1);
          };
        },
        replaceReducer: function replaceReducer(nextReducer) {
          if (!isFunction(nextReducer)) {
            throw new Error("Expected the nextReducer to be a function.");
          }
        }
      };
      return self2;
    }
    var mockStoreWithMiddleware = applyMiddleware.apply(void 0, toConsumableArray(middlewares2))(mockStoreWithoutMiddleware);
    return mockStoreWithMiddleware();
  };
}
function wrapWithProvider(Component, store, props) {
  return /* @__PURE__ */ jsx(Provider, { store, children: /* @__PURE__ */ jsx(Component, { ...props }) });
}
const middlewares = [thunk];
const mockStore = configureStore(middlewares);
const createMockedStore = (state = {}) => {
  const store = mockStore(state);
  return store;
};
const testUtils = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  createMockedStore,
  wrapWithProvider
}, Symbol.toStringTag, { value: "Module" }));
export {
  default2 as AlertBanner,
  default3 as AttachedFile,
  default4 as AudioPlayer,
  default5 as Box,
  default6 as Button,
  default7 as Card,
  default8 as CheckBoxGroup,
  default9 as Checkbox,
  default10 as CheckboxPicker,
  default11 as Collapsible,
  default12 as ConditionalWrapper,
  default13 as CopyClipboard,
  default14 as DataDisplay,
  default15 as DataField,
  default16 as DatePicker,
  default17 as Dot,
  f as Dropdown,
  default18 as Form,
  F as FormBuilder,
  default19 as Input,
  L as Login,
  default20 as Message,
  default21 as MultiStateCheckbox,
  default22 as Notification,
  P as PluginHarness,
  default23 as Popup,
  default24 as Radio,
  default25 as RadioGroup,
  default26 as RadioPicker,
  default27 as ReactDatePicker,
  RootState,
  default28 as SectionHeading,
  default29 as Slideout,
  S as SmartAgent,
  Tab,
  default30 as Table,
  default31 as Tabs,
  default32 as Tag,
  default33 as TimeCountdown,
  default34 as TimeElapsed,
  default35 as ToggleSwitch,
  default36 as Tooltip,
  default37 as TriggeredPopup,
  default38 as TypingIndicator,
  default39 as VideoPlayer,
  WYSIWYG,
  EditorState as WYSIWYGEditorState,
  createStore,
  f2 as formatDateTime,
  B as initApp,
  middlewares$1 as middlewares,
  rootReducer,
  testUtils as storeTestUtils
};
