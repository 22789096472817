import { Button, Dropdown, Popup, TimeElapsed } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MoonLoader } from 'react-spinners'
import { deleteScreenRecording } from 'store/contactSearch/contactSearch.actions'
import { selectIsFullScreen, toggleFullScreen } from 'store/screenRecording/screenRecording.reducer'
import DeleteScreenRecordingButton from './DeleteScreenRecording/DeleteSRButton'
import DeleteSRConfirmationPortal from './DeleteScreenRecording/DeleteSRConfirmationPortal'
import OutcomePopUp from './DeleteScreenRecording/OutcomePopUp'
import PauseIcon from './Pause-noFill.svg'
import PlayIcon from './Play-noFill.svg'
import ZoomAndFullScreenButtons from './ZoomAndFullScreenButtons'
import { errorBody, errorHeading } from './constants/deleteSRConfirmationPortal'
import { VideoControlsProps } from './interfaces/VideoControls'
import './video-controls.scss'

export const VideoControls: React.FC<VideoControlsProps> = ({
    setResponse,
    deleteScreenRecordingParams,
    canDeleteScreenRecording,
    playbackState,
    videoZoomControls,
    deleteSR,
}) => {
    const { deleteSRState, updateDeleteSRState } = deleteSR
    const { contactID, recordingDate } = deleteScreenRecordingParams
    const {
        playing: isPlaying,
        setPlaying: setIsPlaying,
        elapsed,
        setPersistentElapsed,
        duration,
        playbackRateOptions,
        playbackRateOnChange,
        currentPlaybackOption,
        playPause,
    } = playbackState

    const dispatch = useDispatch()
    const isFullScreen = useSelector(selectIsFullScreen)

    const handleClosePortal = () => {
        updateDeleteSRState((prevState) => ({ ...prevState, isConfirmationPortalOpen: false }))
    }

    const handleFullScreen = () => {
        dispatch(toggleFullScreen())

        if (elapsed) {
            setPersistentElapsed(elapsed)
        }

        if (isPlaying) {
            setIsPlaying(false)
        }
    }

    const handleDeletion = async () => {
        updateDeleteSRState((prevState) => ({
            ...prevState,
            pendingDeletion: true,
        }))

        if (contactID && recordingDate) {
            const response = (await dispatch(
                deleteScreenRecording(contactID, recordingDate),
            )) as unknown as { success: boolean; error?: string }

            // response is saved in state in parent component to trigger a pop up with successful message.
            // This VideoControls component in that case won't be rendered, and will be swapped for the Audio Controls component
            setResponse(response)

            if (response.error) {
                updateDeleteSRState((prevState) => ({
                    ...prevState,
                    pendingDeletion: false,
                    isConfirmationPortalOpen: false,
                    isErrorOpen: true,
                    error: response.error,
                }))
            }
        }
    }

    if (!videoZoomControls) return null

    return (
        <>
            {deleteSRState.isErrorOpen ? (
                <OutcomePopUp
                    body={deleteSRState.error || errorBody}
                    heading={errorHeading}
                    closeAction={() =>
                        updateDeleteSRState((prevState) => ({
                            ...prevState,
                            isErrorOpen: false,
                        }))
                    }
                />
            ) : null}

            {deleteSRState.pendingDeletion && (
                <Popup className="delete-sr-loading" center>
                    <MoonLoader />
                </Popup>
            )}
            {deleteSRState.isConfirmationPortalOpen && (
                <DeleteSRConfirmationPortal actions={{ handleClosePortal, handleDeletion }} />
            )}
            <div
                className="sa-videoplayer-controls-wrapper"
                data-testid="screen-recording-video-controls"
            >
                <div className="sa-videoplayer-controls">
                    <DeleteScreenRecordingButton
                        portalState={{ deleteSRState, updateDeleteSRState }}
                        canDeleteScreenRecording={canDeleteScreenRecording}
                    />
                    <div className="timeelapsed-wrapper">
                        <TimeElapsed
                            ariaLive="off"
                            hours
                            format="hh:mm:ss"
                            noTick
                            date={TimeElapsed.from(elapsed ?? 0)}
                        />
                        <span>{' / '}</span>
                        <TimeElapsed
                            ariaLive="off"
                            hours
                            format="hh:mm:ss"
                            noTick
                            date={TimeElapsed.from(duration)}
                        />
                    </div>

                    <Button
                        data-testid="play-pause-button-video"
                        style={{ margin: '0px 3px' }}
                        onClick={playPause}
                    >
                        <img
                            src={isPlaying ? PauseIcon : PlayIcon}
                            alt={isPlaying ? 'Pause' : 'Play'}
                            title={isPlaying ? 'Pause' : 'Play'}
                        />
                    </Button>

                    <Dropdown
                        options={playbackRateOptions}
                        onChange={playbackRateOnChange}
                        value={currentPlaybackOption?.label || ''}
                    />
                    <ZoomAndFullScreenButtons
                        videoZoomControls={videoZoomControls}
                        isFullScreen={isFullScreen}
                        handleFullScreen={handleFullScreen}
                    />
                </div>
            </div>
        </>
    )
}
