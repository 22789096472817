var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { PureComponent } from "react";
import ReactDOM from "react-dom";
import Button from "./Button.mjs";
import { t as trapBrowserFocusToElement } from "./utils-CO0rFpS6.mjs";
const CloseImg = "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20xmlns:xlink='http://www.w3.org/1999/xlink'%20width='24'%20height='24'%3e%3cdefs%3e%3cpath%20id='a'%20d='M6.18%204.999l3.576%203.576a.833.833%200%2011-1.178%201.178L5%206.177%201.422%209.756A.833.833%200%2001.244%208.578l3.58-3.58L.246%201.423A.833.833%200%20111.425.244L5%203.82%208.575.247a.833.833%200%20011.178%201.178L6.18%204.999z'/%3e%3c/defs%3e%3cg%20fill='none'%20fill-rule='evenodd'%20transform='translate(7%207)'%3e%3cmask%20id='b'%20fill='%23fff'%3e%3cuse%20xlink:href='%23a'/%3e%3c/mask%3e%3cuse%20fill='%234A90E2'%20xlink:href='%23a'/%3e%3cg%20fill='%23292623'%20mask='url(%23b)'%3e%3cpath%20d='M-6-6h22v22H-6z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
const body = document.getElementsByTagName("body")[0];
class Popup extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "state", {
      open: true
    });
    __publicField(this, "popup");
    __publicField(this, "aside", null);
    __publicField(this, "x", 0);
    __publicField(this, "y", 0);
    __publicField(this, "move", false);
    __publicField(this, "checkOutsideClick", (e) => {
      var _a, _b, _c;
      if (this.props.closeOnClickOutside && !this.aside.contains(e.target) && !((_c = (_b = (_a = this.props).getTriggerNode) == null ? void 0 : _b.call(_a)) == null ? void 0 : _c.contains(e.target))) {
        this.props.onClose();
      }
    });
    __publicField(this, "onMouseDown", (ev) => {
      if (!this.props.move)
        return;
      this.move = true;
      this.x = ev.clientX;
      this.y = ev.clientY;
    });
    __publicField(this, "onMouseMove", (ev) => {
      if (!this.move || !this.popup)
        return;
      this.popup.style.left = Number(this.popup.offsetLeft + (ev.clientX - this.x)) + "px";
      this.popup.style.top = Number(this.popup.offsetTop + (ev.clientY - this.y)) + "px";
      this.popup.style.margin = "initial";
      this.x = ev.clientX;
      this.y = ev.clientY;
    });
    __publicField(this, "onMouseUp", () => {
      this.move = false;
    });
    __publicField(this, "onPrevent", (ev) => {
      if (this.props.move) {
        ev.stopPropagation();
      }
    });
    __publicField(this, "onContainerClick", (ev) => {
      if (!this.props.onClose)
        return;
      let el = ev.target;
      let closePopup = true;
      if (this.props.stopPropagation)
        ev.stopPropagation();
      while (el) {
        if (el.classList.contains("popup")) {
          closePopup = false;
        }
        el = el.parentElement;
      }
      if (closePopup) {
        this.props.onClose();
      }
    });
    __publicField(this, "onKeypress", (e) => {
      if (e.key === "Escape" && this.props.onClose) {
        this.props.onClose();
      }
      this.aside && trapBrowserFocusToElement(e, this.aside);
    });
    __publicField(this, "getStyle", () => {
      const style = {};
      const { top, left, right, bottom } = this.props;
      if (top)
        style.top = top + "px";
      if (left)
        style.left = left + "px";
      if (right)
        style.right = right + "px";
      if (bottom)
        style.bottom = bottom + "px";
      return style;
    });
  }
  componentDidMount() {
    var _a, _b, _c, _d;
    (_b = (_a = this.props) == null ? void 0 : _a.onOpen) == null ? void 0 : _b.call(_a);
    if (this.props.closeOnClickOutside) {
      if (!this.props.onClose) {
        console.warn("closeOnClickOutside prop given in popup without onClose function.");
      }
      document.addEventListener("mousedown", this.checkOutsideClick, false);
    }
    if (this.props.move) {
      this.onMouseUp = this.onMouseUp.bind(this);
      this.onMouseMove = this.onMouseMove.bind(this);
      body.addEventListener("mouseup", this.onMouseUp);
      body.addEventListener("mousemove", this.onMouseMove);
      if (this.props.noShadow && this.props.center) {
        this.popup.style.top = (body.offsetHeight - this.popup.offsetHeight) / 2 + "px";
        this.popup.style.bottom = "initial";
      }
    }
    this.popup.style.maxHeight = Number(window.innerHeight - this.popup.offsetTop - 10) + "px";
    setTimeout(() => {
      var _a2, _b2;
      if (this.props.noFocusOnOpen)
        return;
      const popupElement = (_a2 = this.popup) == null ? void 0 : _a2.querySelector("button, input");
      if (popupElement) {
        popupElement.focus();
      } else {
        (_b2 = this.aside) == null ? void 0 : _b2.focus();
      }
    }, 50);
    (_d = (_c = this.props).getTriggeredPopup) == null ? void 0 : _d.call(_c, this.popup);
  }
  componentWillUnmount() {
    var _a;
    if (this.props.move) {
      body.removeEventListener("mouseup", this.onMouseUp);
      body.removeEventListener("mousemove", this.onMouseMove);
    }
    if (this.props.closeOnClickOutside) {
      document.removeEventListener("mousedown", this.checkOutsideClick, false);
    }
    if (!this.props.closeOnClickOutside && !this.props.closeOnClick && !this.props.close) {
      (_a = this.props) == null ? void 0 : _a.onClose();
    }
  }
  render() {
    if (!this.state.open)
      return null;
    const {
      id,
      move,
      close,
      center,
      noShadow,
      small,
      className,
      closeOnClick,
      fromCenter,
      onClose,
      children,
      title
    } = this.props;
    const containerClasses = classNames({
      "popup-container": true,
      "no-shadow": !!noShadow,
      small: !!small,
      "centered-popup": fromCenter,
      move: !!move,
      center: !!center,
      [className]: !!className
    });
    return ReactDOM.createPortal(
      /* @__PURE__ */ jsx("div", { id, className: containerClasses, children: /* @__PURE__ */ jsxs(
        "aside",
        {
          ref: (r) => this.aside = r,
          title,
          role: "dialog",
          tabIndex: -1,
          style: this.getStyle(),
          className: "popup",
          onMouseDown: move ? this.onMouseDown : void 0,
          onClick: this.onContainerClick,
          onKeyDown: this.onKeypress,
          children: [
            close ? /* @__PURE__ */ jsx(Button, { elastic: true, className: "popup-close", onClick: onClose, children: /* @__PURE__ */ jsx("img", { src: CloseImg, alt: "close" }) }) : null,
            /* @__PURE__ */ jsx(
              "div",
              {
                ref: (r) => this.popup = r,
                className: "popup-inner",
                onClick: closeOnClick && onClose ? () => onClose() : () => {
                },
                children
              }
            )
          ]
        }
      ) }),
      body
    );
  }
}
__publicField(Popup, "defaultProps", {
  small: false,
  center: false,
  move: false,
  closeOnClickOutside: false,
  closeOnClick: false,
  stopPropagation: true,
  close: false,
  noShadow: false,
  onClose: () => {
  },
  onOpen: void 0
});
export {
  Popup as default
};
