var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { jsx } from "react/jsx-runtime";
import { PureComponent } from "react";
class TimeElapsed extends PureComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "interval");
    __publicField(this, "zeroTime", "00:00");
    __publicField(this, "state", {
      time: this.zeroTime
    });
    __publicField(this, "initialise", () => {
      this.interval = window.setInterval(() => {
        this.setState({ time: this.stringify(this.getTimeElapsed()) });
        this.props.onChange(this.getSecondsFromStart());
      }, 1e3);
    });
    __publicField(this, "getTimeElapsed", () => {
      const time = this.getSecondsFromStart();
      let [days, hours, minutes, seconds] = [0, 0, 0, 0];
      if (this.props.days) {
        days = Math.floor(time / 86400);
        hours = Math.floor((time - days * 86400) / 3600);
        minutes = Math.floor((time - days * 86400 - hours * 3600) / 60);
        seconds = time - days * 86400 - hours * 3600 - minutes * 60;
      } else if (this.props.hours) {
        hours = Math.floor(time / 3600);
        minutes = Math.floor((time - hours * 3600) / 60);
        seconds = time - hours * 3600 - minutes * 60;
      } else if (this.props.minutes) {
        minutes = Math.floor(time / 60);
        seconds = time - minutes * 60;
      } else {
        seconds = time;
      }
      return {
        days,
        hours,
        minutes,
        seconds
      };
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date && this.props.noTick) {
      this.setState({ time: this.stringify(this.getTimeElapsed()) });
    }
  }
  componentDidMount() {
    this.setState({ time: this.stringify(this.getTimeElapsed()) });
    if (this.props.noTick)
      return;
    this.initialise();
  }
  componentWillUnmount() {
    if (this.props.noTick)
      return;
    clearInterval(this.interval);
  }
  getSecondsFromStart() {
    const { date, maxSeconds } = this.props;
    if (!date)
      return 0;
    const seconds = Math.floor(((/* @__PURE__ */ new Date()).getTime() - date.getTime()) / 1e3);
    if (maxSeconds && maxSeconds < seconds) {
      clearInterval(this.interval);
    }
    if (seconds < 0)
      return 0;
    return seconds;
  }
  inferFormat() {
    const { days, hours, minutes } = this.props;
    const format = ["ss"];
    if (minutes)
      format.unshift("mm");
    if (hours)
      format.unshift("hh");
    if (days)
      format.unshift("dd");
    return format.join(":");
  }
  stringify(hms) {
    const an = this.props.annotate;
    const daysStr = hms.days !== void 0 ? `${hms.days}${an ? "d " : ":"}` : "";
    const hoursStr = hms.hours !== void 0 ? `${hms.hours < 10 ? "0" : ""}${hms.hours}${an ? "h " : ":"}` : "";
    const minsStr = `${hms.minutes < 10 ? "0" : ""}${hms.minutes}${an ? "m " : ":"}`;
    const secondsStr = `${hms.seconds < 10 ? "0" : ""}${hms.seconds}${an ? "s " : ":"}`;
    const format = this.props.format || this.inferFormat();
    let formatted = format.split(":").reduce((str, f) => {
      if (!["mm", "ss", "dd", "hh"].includes(f))
        throw new Error("Invalid time format");
      switch (f) {
        case "ss":
          return str + secondsStr;
        case "mm":
          return str + minsStr;
        case "hh":
          return str + hoursStr;
        case "dd":
          return str + daysStr;
        default:
          return str;
      }
    }, "");
    if (formatted.substr(-1) === ":") {
      formatted = formatted.slice(0, formatted.length - 1);
    }
    return formatted;
  }
  render() {
    const { displayOnZero, className, ariaHidden, ariaLive } = this.props;
    return this.state.time === this.zeroTime && displayOnZero ? /* @__PURE__ */ jsx("span", { className, children: displayOnZero }) : /* @__PURE__ */ jsx("time", { "aria-live": ariaLive, "aria-hidden": ariaHidden, dateTime: this.state.time, children: this.state.time });
  }
}
__publicField(TimeElapsed, "defaultProps", {
  onChange: () => {
  },
  minutes: true
});
__publicField(TimeElapsed, "from", (seconds) => {
  const date = /* @__PURE__ */ new Date();
  date.setSeconds(date.getSeconds() - seconds);
  return date;
});
export {
  TimeElapsed as default
};
