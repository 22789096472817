import { jsx, jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import Button from "./Button.mjs";
import { t as trapBrowserFocusToElement } from "./utils-CO0rFpS6.mjs";
const slideoutTransition = (Component) => {
  const SlideoutWrapper = (props) => {
    const { isMounted, slideDuration } = props;
    const [shouldRender, setShouldRender] = useState(isMounted);
    let currentTimeout = void 0;
    useEffect(() => {
      if (isMounted === false) {
        currentTimeout = window.setTimeout(() => shouldRender && setShouldRender(false), slideDuration);
      } else if (isMounted === true) {
        currentTimeout && clearTimeout(currentTimeout);
        !shouldRender && setShouldRender(true);
      }
      return () => {
        currentTimeout && clearTimeout(currentTimeout);
      };
    }, [isMounted]);
    return shouldRender ? /* @__PURE__ */ jsx(Component, { ...props }) : null;
  };
  return SlideoutWrapper;
};
const Slideout = ({
  width,
  widthUnits,
  from,
  slideDuration,
  offset,
  isMounted,
  children,
  overlay,
  onOverlayClick,
  className
}) => {
  const widthValue = `${width}${widthUnits ?? "px"}`;
  const offsetValue = `${offset ?? 0}px`;
  const [slideoutCSS, setSlideoutCSS] = useState({
    width: widthValue,
    transition: `${slideDuration / 1e3}s`,
    [from]: `-${widthValue}`
  });
  const slideoutRef = useRef(null);
  useEffect(() => {
    var _a;
    isMounted && ((_a = slideoutRef.current) == null ? void 0 : _a.focus());
    setSlideoutCSS({
      ...slideoutCSS,
      [from]: isMounted ? offsetValue : `-${widthValue}`
    });
  }, [isMounted]);
  const onKeypress = (event) => {
    slideoutRef.current && isMounted && trapBrowserFocusToElement(event, slideoutRef.current);
  };
  return /* @__PURE__ */ jsxs(
    "div",
    {
      className: classNames({
        "sa-slideout-wrapper": true,
        [className]: !!className
      }),
      ref: slideoutRef,
      tabIndex: isMounted ? 0 : -1,
      "aria-label": "Slide out component",
      onKeyDown: onKeypress,
      role: "dialog",
      children: [
        /* @__PURE__ */ jsx("div", { style: slideoutCSS, "data-testid": "sa-slideout", role: "form", className: "sa-slideout", children: isMounted && children ? children : null }),
        overlay && isMounted && /* @__PURE__ */ jsx(
          Button,
          {
            type: "button",
            className: "sa-slideout-overlay",
            onClick: onOverlayClick,
            style: { [from]: offsetValue },
            title: "Close slide out"
          }
        )
      ]
    }
  );
};
const index = slideoutTransition(Slideout);
export {
  index as default
};
