import { Button, Popup } from '@missionlabs/smartagent-app-components'
import React from 'react'
import { H } from 'react-accessible-headings'

export interface IOutcomePopUpProps {
    heading: string
    body: string
    closeAction: () => void
}

const OutcomePopUp: React.FC<IOutcomePopUpProps> = ({ heading, body, closeAction }) => {
    return (
        <Popup className="video-popup" center>
            <H>{heading}</H>
            <p>{body}</p>
            <div className="single-button-wrapper">
                <Button alt="Close" title="Close" large styling="white" onClick={closeAction}>
                    Close
                </Button>
            </div>
        </Popup>
    )
}

export default OutcomePopUp
