import { Dropdown } from '@missionlabs/smartagent-app-components'
import * as React from 'react'
import { useState } from 'react'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsFullScreen, toggleFullScreen } from 'store/screenRecording/screenRecording.reducer'
import { Controls } from './Controls'
import OutcomePopUp from './DeleteScreenRecording/OutcomePopUp'
import DownloadButton from './DownloadButton'
import { VideoControls } from './VideoControls'
import { successBody, successHeading } from './constants/deleteSRConfirmationPortal'
import { PlaybackRateOption } from './interfaces/AudioPlayer'
import { IHeaderProps } from './interfaces/Header'
import { IDeleteSRState } from './interfaces/VideoControls'

const playbackRateOptions: PlaybackRateOption[] = [
    { label: 'Speed: 0.5x', data: '0.5' },
    { label: 'Speed: 1x', data: '1.0' },
    { label: 'Speed: 2x', data: '2.0' },
    { label: 'Speed: 3x', data: '3.0' },
]
// default 1x
const defaultSelectedPlaybackOption = playbackRateOptions[1]

const Header: React.FunctionComponent<IHeaderProps> = ({
    deleteScreenRecordingParams,
    screenRecordingURL,
    featureFlags,
    contactID,
    recordingDate,
    signedUrl,
    title,
    refs,
    playbackState,
    videoZoomControls,
}) => {
    const [deleteSRState, updateDeleteSRState] = useState<IDeleteSRState>({
        isConfirmationPortalOpen: false,
        pendingDeletion: false,
        error: undefined,
        isErrorOpen: false,
        isSuccessOpen: false,
    })
    const [response, setResponse] = useState<{ success: boolean; error?: string } | null>(null)

    const [currentPlaybackOption, setCurrentPlaybackOption] = React.useState(
        defaultSelectedPlaybackOption,
    )

    const { audioRef, videoRef } = refs
    const { elapsed, playing, setPlaying, setPersistentElapsed, duration, setElapsed } =
        playbackState

    const playbackRateOnChange = (option: PlaybackRateOption) => {
        setCurrentPlaybackOption(option)
        if (audioRef?.current) audioRef.current.playbackRate = parseFloat(option.data)
        if (videoRef?.current) videoRef.current.playbackRate = parseFloat(option.data)
    }

    const playPause = () => {
        setPlaying(!playing)
    }

    const { canDownload, canDeleteScreenRecording } = featureFlags
    const dispatch = useDispatch()
    const isFullScreen = useSelector(selectIsFullScreen)

    React.useEffect(() => {
        if (response?.success) {
            updateDeleteSRState((prevState) => ({
                ...prevState,
                pendingDeletion: false,
                isConfirmationPortalOpen: false,
                isSuccessOpen: true,
            }))
        }
    }, [response])
    return (
        <>
            {deleteSRState.isSuccessOpen ? (
                <OutcomePopUp
                    heading={successHeading}
                    body={successBody}
                    closeAction={() => {
                        updateDeleteSRState((prevState) => ({
                            ...prevState,
                            isSuccessOpen: false,
                        }))
                        if (isFullScreen && toggleFullScreen) {
                            dispatch(toggleFullScreen())
                        }
                    }}
                />
            ) : null}

            <div className="row middle evenly grow">
                <H>{title}</H>
                {screenRecordingURL ? (
                    <VideoControls
                        setResponse={setResponse}
                        deleteSR={{ deleteSRState, updateDeleteSRState }}
                        deleteScreenRecordingParams={deleteScreenRecordingParams}
                        canDeleteScreenRecording={canDeleteScreenRecording}
                        playbackState={{
                            playing,
                            setPlaying,
                            playPause,
                            elapsed,
                            setElapsed,
                            setPersistentElapsed,
                            playbackRateOptions,
                            playbackRateOnChange,
                            currentPlaybackOption,
                            duration,
                        }}
                        videoZoomControls={videoZoomControls}
                    />
                ) : (
                    <>
                        <Controls
                            elapsed={elapsed}
                            isPlaying={playing}
                            total={duration}
                            playPause={playPause}
                        />

                        <Dropdown
                            options={playbackRateOptions}
                            onChange={playbackRateOnChange}
                            value={currentPlaybackOption?.label || ''}
                        />

                        <DownloadButton
                            canDownload={canDownload}
                            contactID={contactID}
                            recordingDate={recordingDate}
                            signedUrl={signedUrl}
                        />
                    </>
                )}
            </div>
        </>
    )
}

export default Header
