import React from 'react'

import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import { ISocialChatMessage } from 'store/chat/chat.state'
import { ChatTranscripts } from 'views/Chat/ChatTranscripts'
import { ContactDetails } from 'widgets/ContactDetails'
import { CallRecording } from 'widgets/CallRecording'
import { IInteraction } from '../../types'

import './chat-view.scss'

export interface Props {
    interaction: IInteraction
}

const ChatView: React.FC<Props> = ({ interaction }) => {
    const hasFeature = useHasFeature()

    const {
        ID: InteractionID,
        attributesUpdated = false,
        channelType,
        transcript,
        attributes,
    } = interaction

    const isSMSCampaignETR = ['SMS CAMPAIGN-MESSAGE', 'SMS MARKETING-MESSAGE'].includes(channelType)
    const isSocialContact = !!attributes?.['sa-social-media-platform']

    const shouldRenderTrancriptForSocialChat =
        (isSocialContact || isSMSCampaignETR) && hasFeature(AppFeatures.CHAT, [AppSubFeatures.SOCIAL_CHAT_TRANSCRIPT])
    const shouldRenderTranscriptForChat =
        !(isSocialContact || isSMSCampaignETR) && hasFeature(AppFeatures.CHAT, [AppSubFeatures.CHAT_HISTORY_TRANSCRIPT])

    return (
        <div className="chat-view-container">
            <div className="chat-view-container-left">
                <ContactDetails contact={attributesUpdated ? interaction : undefined} />
                {hasFeature(AppFeatures.CALL_RECORDING) && (
                        <CallRecording
                            key={interaction.ID}
                            contactID={interaction.ID}
                            recordingDate={interaction.initiationTimestamp}
                            widgetTitle="Screen Recording"
                        />
                )}
            </div>

            {/* This ChatTranscripts component was originally only intended for use with social chat messages, we're also rendering it for regular chats here */}
            {/* per a customer request, if it doesn't fit your use case, consider building/using a different transcript component */}
            {(shouldRenderTrancriptForSocialChat || shouldRenderTranscriptForChat) && (
                <div className="chat-view-container-right">
                    {isSMSCampaignETR ? (
                        transcript?.length ? (
                            <ChatTranscripts
                                socialChatTranscript={transcript as ISocialChatMessage[]}
                            />
                        ) : null
                    ) : (
                        <ChatTranscripts contactID={InteractionID} />
                    )}
                </div>
            )}
        </div>
    )
}

export default ChatView
