import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import React from 'react'
import { Widgets } from 'views/Home/Widgets'
import { IInteraction } from 'views/Metrics/HistoricMetrics/types'
import { CallComprehension } from 'widgets/CallComprehension'
import { CallRecording } from 'widgets/CallRecording'
import CallTranscription from 'widgets/CallTranscription'
import { ContactDetails } from 'widgets/ContactDetails'
import { VideoSharingHistory } from 'widgets/VideoSharing/VideoSharingHistory'
import './voice-widgets.scss'

interface Props {
    interaction: IInteraction
}

const VoiceWidgets: React.FC<Props> = ({ interaction }) => {
    const hasFeature = useHasFeature()
    return (
        <Widgets
            render={(getWidget) => (
                <div data-testid="voice-widgets" className="contact-history-voice-widgets">
                    <CallTranscription contactID={interaction.ID} />
                    <CallComprehension
                        contactID={interaction.ID}
                        widgetTitle="Sentiment analysis"
                    />
                    {hasFeature(AppFeatures.CALL_RECORDING) && (
                        <CallRecording
                            key={interaction.ID}
                            contactID={interaction.ID}
                            recordingDate={interaction.initiationTimestamp}
                            widgetTitle="Audio"
                        />
                    )}
                    {hasFeature(AppFeatures.CALL_DETAILS) && (
                        <ContactDetails contact={interaction} widgetTitle="Additional info" />
                    )}
                    {hasFeature(AppFeatures.VIDEO_MEETING, [
                        AppSubFeatures.VIDEO_MEETING_VIEW_RECORDS,
                    ]) && <VideoSharingHistory contact={interaction} />}
                </div>
            )}
        />
    )
}

export default VoiceWidgets
