import { ContentState, EditorState } from 'draft-js'
import useAppConfig from 'hooks/redux/useAppConfig'
import useChatConnections from 'hooks/redux/useChatConnections'
import useQueuedTasks from 'hooks/redux/useQueuedTasks'
import useTaskConnections from 'hooks/redux/useTaskConnections'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setChatReplyText } from 'store/chat/chat.actions'
import { IChatQuickReply } from 'store/chat/chat.state'
import { SocialMediaPlatform } from 'store/chat/chat.utils'
import { setQueuedTasksReplyText } from 'store/queuedTasks/queuedTasks.actions'
import { setTaskReplyText } from 'store/tasks/tasks.actions'
import { isNil } from 'utils'

import getAllReplies from '../utils/getAllReplies'
import { formatQRWithJson } from '../utils/utils'
import PopupContent from './PopupContent'
import PopupInterface from './PopupInterface'
import styles from './QuickRepliesPopup.module.scss'

const getReplyById = (id: string | number, quickReplies: IChatQuickReply[]): IChatQuickReply =>
    quickReplies.find((qr) => qr.id === id)!

const mapQuickReplies = (quickReplies: IChatQuickReply[]) =>
    quickReplies
        .map((quickReply) => ({
            id: quickReply.id,
            text: quickReply.text,
            content: quickReply.content,
            isCategory: isNil(quickReply.children) ? false : true,
        }))
        .sort((a, b) => (a.text > b.text ? 1 : -1))

interface Props {
    quickReplies: IChatQuickReply[]
    channel:
        | 'Chat'
        | 'Tasks'
        | 'QueuedTasksRichText'
        | 'QueuedTasksPlainText'
        | 'OutboundEmail'
        | 'OutboundSMS'
        | SocialMediaPlatform
    onClose: () => void
    setIsQuickReply: (bool: boolean) => void
    setIsQuickReplyOn?: (bool: boolean) => void
    sendAnalyticsEvent: (eventName: string) => void
    outboundEmailReply?: EditorState
    setOutboundEmailReply?: (editorState: EditorState) => void
    message?: string
    setMessage?: Dispatch<SetStateAction<string>> | ((message: string) => void)
}

interface MappedQuickReply {
    id: IChatQuickReply['id']
    text: IChatQuickReply['text']
    content: IChatQuickReply['content']
    isCategory: boolean
}

interface InitialState {
    data: IChatQuickReply[]
    dataToRender: MappedQuickReply[]
}

const getFilteredReplies = (search: string, replies: IChatQuickReply[]) => {
    if (search) {
        return replies.filter((qr) => {
            return qr.text.toLowerCase().includes(search.toLowerCase())
        })
    }

    return []
}

const getQuickRepliesToRender = (
    isSearchTriggered: boolean,
    filteredQuickReplies: IChatQuickReply[],
    quickReplies: MappedQuickReply[],
) => {
    if (isSearchTriggered) {
        return mapQuickReplies(filteredQuickReplies)
    }

    return quickReplies
}

const QuickRepliesPopup = ({
    quickReplies,
    onClose,
    channel,
    setIsQuickReply,
    sendAnalyticsEvent,
    setIsQuickReplyOn,
    outboundEmailReply,
    setOutboundEmailReply,
    message,
    setMessage,
}: Props) => {
    const dispatch = useDispatch()
    const chat = useChatConnections('selected')
    const task = useTaskConnections('selected')
    const queuedTasks = useQueuedTasks()
    const appConfig = useAppConfig()

    const [search, setSearch] = useState('')
    const [allReplies] = useState(getAllReplies(quickReplies))
    const [repliesForRendering, updateRepliesHierarchy] = useState<Array<InitialState>>([
        {
            data: quickReplies,
            dataToRender: mapQuickReplies(quickReplies),
        },
    ])

    const addReplyToRenderedArray = (quickReplies: IChatQuickReply[]) =>
        updateRepliesHierarchy((oldArray) => [
            ...oldArray,
            {
                data: quickReplies,
                dataToRender: mapQuickReplies(quickReplies),
            },
        ])

    const addReplyForRendering = (id: IChatQuickReply['id']) => {
        const lastRenderedReply = getReplyById(
            id,
            repliesForRendering[repliesForRendering.length - 1].data,
        )

        const lastReplyChildren = lastRenderedReply.children

        if (lastReplyChildren) {
            addReplyToRenderedArray(lastReplyChildren)
        }
    }

    const handleBackButtonClick = (e: React.FormEvent) => {
        e.preventDefault()
        if (repliesForRendering.length === 1) {
            return
        }

        updateRepliesHierarchy((oldArray) => oldArray.slice(0, oldArray.length - 1))
    }

    const setReply = useCallback(
        (args: { text: string; isProbablyJson: boolean }) => {
            switch (channel) {
                case 'Chat':
                case 'SMS':
                case SocialMediaPlatform.FACEBOOK:
                case SocialMediaPlatform.TWITTER:
                case SocialMediaPlatform.INSTAGRAM:
                case SocialMediaPlatform.WHATSAPP:
                    if (appConfig?.appendQuickReplies) {
                        dispatch(
                            setChatReplyText({
                                id: chat!.id,
                                text: chat?.reply.text + args.text,
                                isProbablyJson: args.isProbablyJson,
                            }),
                        )
                    } else {
                        dispatch(
                            setChatReplyText({
                                id: chat!.id,
                                text: args.text,
                                isProbablyJson: args.isProbablyJson,
                            }),
                        )
                    }
                    break
                case 'Tasks':
                    const taskQRMessage = formatQRWithJson(args.text)
                    dispatch(
                        setTaskReplyText({
                            id: task!.id,
                            text: taskQRMessage,
                            isProbablyJson: args.isProbablyJson,
                        }),
                    )
                    break
                case 'QueuedTasksPlainText':
                    dispatch(setQueuedTasksReplyText('plain', args.text, args.isProbablyJson))
                    break
                case 'QueuedTasksRichText':
                    dispatch(setQueuedTasksReplyText('rich', args.text, args.isProbablyJson))
                    break
                case 'OutboundEmail':
                    const outboundEmailContent = (outboundEmailReply ?? EditorState.createEmpty())
                        .getCurrentContent()
                        .getPlainText('\u0001')
                    const newContentState = ContentState.createFromText(
                        outboundEmailContent.length > 0
                            ? `${outboundEmailContent}\n${args.text}`
                            : args.text,
                    )
                    setOutboundEmailReply?.(EditorState.createWithContent(newContentState))
                    break
                case 'OutboundSMS':
                    setMessage?.(
                        message && message.length > 0 ? `${message}\n${args.text}` : args.text,
                    )
                    break
            }
        },
        [dispatch, chat, task, queuedTasks],
    )

    const setReplyFromQuickReply = (text: string, isProbablyJson: boolean) => {
        sendAnalyticsEvent('use_qr_inserted')
        setIsQuickReply(true)
        setIsQuickReplyOn && setIsQuickReplyOn(true)
        setReply({ text, isProbablyJson })
        onClose()
    }

    const isSearchTriggered = () => !!search

    const filteredReplies = getFilteredReplies(search, allReplies)

    const quickRepliesToRender = getQuickRepliesToRender(
        isSearchTriggered(),
        filteredReplies,
        repliesForRendering[repliesForRendering.length - 1].dataToRender,
    )

    return (
        <div className={styles.wrapper}>
            <div className={styles.controls}>
                <PopupInterface
                    onBackButtonClick={handleBackButtonClick}
                    onCloseButtonClick={onClose}
                    showBackButton={!isSearchTriggered() && repliesForRendering.length > 1}
                    onSearchChange={setSearch}
                    searchInputValue={search}
                />
            </div>

            <div className={styles.content}>
                <PopupContent
                    isSearchTriggered={isSearchTriggered()}
                    quickReplies={quickRepliesToRender}
                    onCategoryClick={addReplyForRendering}
                    onReplyClick={setReplyFromQuickReply}
                />
            </div>
        </div>
    )
}

export default QuickRepliesPopup
