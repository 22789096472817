const trapBrowserFocusToElement = (event, element) => {
  if (event.key !== "Tab")
    return;
  const focusableInnerElements = element.querySelectorAll(
    'a[href], area[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex="0"]'
  );
  if (!focusableInnerElements.length)
    return;
  const firstElement = focusableInnerElements[0];
  const lastElement = focusableInnerElements[focusableInnerElements.length - 1];
  if (!event.shiftKey && document.activeElement === lastElement) {
    firstElement.focus();
    event.preventDefault();
  }
  if (event.shiftKey && document.activeElement === firstElement) {
    lastElement.focus();
    event.preventDefault();
  }
};
const getEnvironment = () => {
  const hostname = window.location.hostname;
  const params = new URLSearchParams(window.location.search);
  const env = params.get("env");
  if (hostname === "artefacts.smartagent.app" && env) {
    return env;
  }
  return process.env.REACT_APP_ENV || "dev";
};
const getBaseUrl = (env) => {
  if (process.env.REACT_APP_BASE_URL) {
    return process.env.REACT_APP_BASE_URL;
  }
  const BASE_URL_MAP = {
    prod: "services.smartagent.app",
    beta: "services.smartagent.app",
    uat: "uat-services.smartagent.app",
    dev: "dev-services.smartagent.app"
  };
  return BASE_URL_MAP[env];
};
function getParameterByName(name, url = window.location.href) {
  return new URL(url).searchParams.get(name);
}
function getSubdomain() {
  const parts = window.location.hostname.split(".");
  if (parts.length < 3)
    return;
  if (parts[0].startsWith("dev-")) {
    return parts[0].split("-")[1];
  }
  if (parts[0] && parts[0].startsWith("beta-")) {
    return parts[0].split("-")[1];
  }
  if (parts[0] && parts[0].startsWith("uat-")) {
    return parts[0].split("-")[1];
  }
  return parts[0];
}
function getHashParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[#&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);
  if (!results)
    return null;
  if (!results[2])
    return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export {
  getBaseUrl as a,
  getParameterByName as b,
  getSubdomain as c,
  getHashParameterByName as d,
  getEnvironment as g,
  trapBrowserFocusToElement as t
};
