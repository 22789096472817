import { Button } from '@missionlabs/smartagent-app-components'
import React from 'react'
import DeleteScreenRecordingIcon from '../../../images/delete-screen-recording-icon.svg'
import { IPortalState } from '../interfaces/VideoControls'

interface DeleteSRButtonProps {
    canDeleteScreenRecording: boolean
    portalState: IPortalState
}

const DeleteSRButton: React.FC<DeleteSRButtonProps> = ({
    canDeleteScreenRecording,
    portalState,
}) => {
    const { updateDeleteSRState } = portalState

    const handleOpenPortal = () => {
        updateDeleteSRState((prevState) => ({ ...prevState, isConfirmationPortalOpen: true }))
    }

    return (
        canDeleteScreenRecording && (
            <Button
                id="delete-sr-button-id"
                data-testid="delete-sr-button-id"
                onClick={handleOpenPortal}
            >
                <img
                    src={DeleteScreenRecordingIcon}
                    alt="Delete Screen Recording"
                    title="Delete Screen Recording"
                />
            </Button>
        )
    )
}

export default DeleteSRButton
