import type { IETR } from '@missionlabs/smartagent-lib-shared'
import { makeRequest } from 'services/api'
import ICallContact, { IOriginalContactState } from 'store/contact/contact.state'
import { IRealTimeQueueContact } from 'store/directory/directory.state'
import { IQueueHistoricalData } from 'store/metrics/metrics.state'
import { IStrObj, paramsToQueryString, timestampToYYYYMMDD } from 'utils'
import { IComprehension } from 'widgets/CallComprehension/ComprehensionInfo'
import { ICallRecordingData, IRecordingData } from 'widgets/CallRecording/interfaces/AudioPlayer'
import { ITranscript } from 'widgets/CallTranscription'

export async function getCallRecording(
    companyID: string,
    instanceID: string,
    token: string,
    contactID: string,
    date: number,
) {
    const _date = timestampToYYYYMMDD(date)

    const response = await makeRequest<ICallRecordingData>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/recording?date=${_date}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getScreenRecording(
    contactID: string,
    companyID: string,
    instanceID: string,
    token: string,
    date: number,
) {
    const _date = timestampToYYYYMMDD(date)

    const response = await makeRequest<IRecordingData>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/screen-recording?date=${_date}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getCallLog(
    companyID: string,
    instanceID: string,
    token: string,
    date: string,
) {
    const response = await makeRequest<ICallContact[]>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/me?fromDate=${date}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getContact(contactID: string) {
    const response = await makeRequest<ICallContact>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/${contactID}`,
        method: 'get',
    })

    return response.data
}

export async function getLiveContact(contactID: string) {
    const response = await makeRequest<IOriginalContactState>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/live/${contactID}`,
        method: 'get',
    })

    return response.data
}

export async function getTranscription(
    companyID: string,
    instanceID: string,
    token: string,
    contactID: string,
    inititationTimestamp?: number,
    disconnectTimestamp?: number,
) {
    const timestamp = inititationTimestamp
        ? new Date(inititationTimestamp).toISOString()
        : undefined
    const contactEndTime = disconnectTimestamp ? `${disconnectTimestamp}` : undefined

    const qs = paramsToQueryString({ timestamp, contactEndTime })

    const response = await makeRequest<ITranscript>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/transcription?${qs}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getComprehension(
    companyID: string,
    instanceID: string,
    token: string,
    contactID: string,
    disconnectedTimestamp?: number,
    connectedToAgentTimestamp?: number,
) {
    let queryString = ''
    if (disconnectedTimestamp && connectedToAgentTimestamp) {
        const date = timestampToYYYYMMDD(connectedToAgentTimestamp)
        const contactEndTime = Math.floor(connectedToAgentTimestamp / 1000)
        queryString = `?date=${date}&contactEndTime=${contactEndTime}`
    }

    const response = await makeRequest<IComprehension>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/comprehension${queryString}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function updateContactAttributes(
    companyID: string,
    instanceID: string,
    contactID: string,
    token: string,
    attributes: IStrObj,
) {
    const response = await makeRequest<void>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/attributes`,
        method: 'put',
        data: attributes,
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response.data
}

export async function getContacts(
    companyID: string,
    instanceID: string,
    token: string,
    queryString: string,
) {
    const response = await makeRequest<ICallContact[]>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts?${queryString}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getEventTraceRecords(queryString: string) {
    const response = await makeRequest<IETR[]>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/events?${queryString}`,
        method: 'get',
    })
    return response.data
}

export async function getContactsById(contactIDs: string[], filterByAgentTags: boolean) {
    const response = await makeRequest<ICallContact[]>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts${filterByAgentTags ? '?filterByAgentTags=true' : ''}`,
        method: 'post',
        data: {
            contactIDs,
        },
    })
    return response
}

export async function getChatTranscript(
    companyID: string,
    instanceID: string,
    token: string,
    contactID: string,
) {
    const response = await makeRequest({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/chat-transcript`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response
}

export async function fetchChatTranscript(contactID: string) {
    const response = await makeRequest({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/${contactID}/chat-transcript`,
        method: 'get',
    })
    return response
}

export async function getConnectChatAttachmentUrl(contactID: string, attachmentID: string) {
    const response = await makeRequest({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/${contactID}/attachments/${attachmentID}`,
        method: 'get',
    })

    return response.data
}

export async function getAgentMetrics(
    companyID: string,
    instanceID: string,
    token: string,
    from: string,
    filters: IStrObj = {},
) {
    filters.groupBy = 'agent'
    filters.from = from
    const response = await makeRequest({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/call-summaries?${paramsToQueryString(
            filters,
        )}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getQueueMetrics(
    companyID: string,
    instanceID: string,
    token: string,
    from: string,
    filters: IStrObj = {},
) {
    filters.groupBy = 'queue'
    filters.from = from
    const response = await makeRequest<IQueueHistoricalData[]>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/call-summaries?${paramsToQueryString(
            filters,
        )}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}
export async function allocateNumber(
    companyID: string,
    instanceID: string,
    number: string,
    token: string,
) {
    const response = await makeRequest<{
        numberE164: string
        allocationE164: string
        allocationExpires: number
    }>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/allocate-number`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
        data: {
            allocationE164: number.replace('+', ''), //Dont allow +
        },
    })

    return response.data
}

export async function getQueueMetrics1(
    companyID: string,
    token: string,
    instanceID: string,
    queues: string,
    timeRange?: string | number,
) {
    const response = await makeRequest({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/call-summaries?queueIDs=${queues.split(
            ',',
        )}&groupBy=queue&from=${timeRange}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getAgentMetrics1(
    companyID: string,
    token: string,
    instanceID: string,
    agentID: string,
    timeRange?: string | number,
) {
    const response = await makeRequest({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/call-summaries?agentID=${agentID}&groupBy=queue&from=${timeRange}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response.data
}

export async function getRealTimeContacts(companyID: string, instanceID: string, token: string) {
    const response = await makeRequest<IRealTimeQueueContact[]>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/realtime-contacts`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function getSingleRealTimeContacts(
    contactID: string,
    companyID: string,
    instanceID: string,
    token: string,
) {
    const response = await makeRequest<IRealTimeQueueContact>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/realtime-contacts/${contactID}`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function postPluckContact(
    contactID: string,
    companyID: string,
    instanceID: string,
    token: string,
) {
    const response = await makeRequest<IRealTimeQueueContact>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/realtime-contacts/${contactID}/pluck`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function postParkContact(
    contactID: string,
    companyID: string,
    instanceID: string,
    token: string,
) {
    const response = await makeRequest<IRealTimeQueueContact>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/realtime-contacts/${contactID}/park`,
        method: 'post',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}

export async function pauseContactRecording(contactID: string, initialContactID?: string) {
    const response = await makeRequest<void>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/${contactID}/recording/pause`,
        method: 'post',
        data: {
            initialContactID,
        },
    })
    return response.data
}

export async function startContactRecording(contactID: string, initialContactID?: string) {
    const response = await makeRequest<void>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/${contactID}/recording/start`,
        method: 'post',
        data: {
            initialContactID,
        },
    })
    return response.data
}

export async function resumeContactRecording(contactID: string, initialContactID?: string) {
    const response = await makeRequest<void>({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/contacts/${contactID}/recording/resume`,
        method: 'post',
        data: {
            initialContactID,
        },
    })
    return response.data
}

export async function getContactAudit(
    companyID: string,
    instanceID: string,
    token: string,
    contactID: string,
) {
    const response = await makeRequest<any[]>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/audit`,
        method: 'get',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })
    return response
}

export async function deleteCustomerData(customerEndpointAddress: string) {
    await makeRequest({
        url: `/contact-service/companies/{companyID}/instances/{instanceID}/endpoint/${customerEndpointAddress}`,
        method: 'delete',
    })
}

export async function deleteScreenRecording(
    companyID: string,
    contactID: string,
    date: number,
    instanceID: string,
    token: string,
) {
    const formattedDate = timestampToYYYYMMDD(date)
    const response = await makeRequest<{}>({
        url: `/contact-service/companies/${companyID}/instances/${instanceID}/contacts/${contactID}/screen-recording?date=${formattedDate}`,
        method: 'delete',
        headers: {
            'X-Amz-Security-Token': token,
        },
    })

    return response
}
