import { Middleware } from '@reduxjs/toolkit'
import * as videoMeetingAPI from 'services/api/api.videoMeeting'
import { clearWidgetAlert, setWidgetAlert } from 'store/app/app.reducer'
import { updateContactAttributes } from 'store/contact/contact.actions'
import { createNotification } from 'store/notification/notification.reducer'
import RootState from 'store/state'
import {
    createMeeting,
    createMeetingFailed,
    createMeetingStart,
    createMeetingSuccess,
    deleteMeeting,
    deleteScreenshots,
    deleteScreenshotsCompleted,
    forgetMeeting,
    getMeetingRecording,
    getMeetingRecordingSuccess,
    getScreenshots,
    getScreenshotsCompleted,
    saveScreenshot,
    saveScreenshotFailed,
    saveScreenshotStart,
    saveScreenshotSuccess,
    setMeetingInvitedSMS,
    setMeetingReqLoading,
    setMeetingStatus,
    setMeetingView,
} from 'store/videoMeeting/videoMeeting.reducer'
import { MeetingViews } from 'store/videoMeeting/videoMeeting.state'
import { isAnyAction } from 'utils'
import updateAttachmentCount from './utils/updateAttachmentCount'

const GENERIC_ERROR_MESSAGE = 'An unknown error occured.'
const GENERIC_ERROR_HEADER = 'Video meeting error'

const videoMeetingMiddleware: Middleware<{}, RootState> = (store) => (next) => async (action) => {
    if (!isAnyAction(action)) return

    const {
        app: { ID, instance = { ID: '' } },
        auth: { token = '' },
        videoMeeting: { historicalMeeting = {}, ...currentMeeting },
    } = store.getState()

    const meetingID = action.payload?.isHistorical
        ? historicalMeeting?.meetingID
        : currentMeeting.meeting?.MeetingId

    const { contactID, screenshots } = action.payload?.isHistorical
        ? historicalMeeting
        : currentMeeting

    switch (action.type) {
        case createMeeting.type: {
            try {
                store.dispatch({ type: createMeetingStart.type })

                const response = await videoMeetingAPI.createMeeting({
                    ...action.payload,
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })

                store.dispatch(createMeetingSuccess(response))
                store.dispatch(setMeetingView({ view: MeetingViews.INVITE }))
                store.dispatch(
                    setWidgetAlert(
                        'Ask the customer to put their phone on speaker ahead of sharing the Live Video Assist so they will still be able to hear you',
                    ),
                )
            } catch (error) {
                console.error('Error creating meeting', error)
                store.dispatch(
                    createMeetingFailed({
                        header: 'Error creating the Live Video Assist meeting',
                        message: error.message,
                    }),
                )
            }
            return next(action)
        }
        case setMeetingInvitedSMS.type: {
            try {
                store.dispatch(clearWidgetAlert())
                store.dispatch(setMeetingReqLoading({ isInviteInProgress: true }))

                if (action.payload.meetingID == '') throw new Error('Meeting ID missing')
                if (action.payload.smsInviteSentTo == '') throw new Error('Number missing')

                const response = await videoMeetingAPI.sendMeetingInvite({
                    ...action.payload,
                    customerEndpointAddress: action.payload.smsInviteSentTo,
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })

                if (response.status !== 200) throw new Error(response.statusText)
                store.dispatch(setMeetingReqLoading({ isInviteInProgress: false }))
                store.dispatch(setMeetingView({ view: MeetingViews.VIDEO }))
                return next(action)
            } catch (error) {
                store.dispatch(
                    createMeetingFailed({
                        header: 'Failed to send invite',
                        message: error.message,
                    }),
                )
                store.dispatch(setMeetingReqLoading({ isInviteInProgress: false }))
                store.dispatch(setMeetingView({ view: MeetingViews.CREATE }))
            }
            return
        }
        case deleteMeeting.type: {
            try {
                store.dispatch(setMeetingReqLoading({ isDeleteInProgress: true }))

                const response = await videoMeetingAPI.deleteMeeting({
                    meetingID: meetingID ?? '',
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })
                store.dispatch(setMeetingReqLoading({ isDeleteInProgress: false }))
                if (response.status !== 200) throw new Error(response.statusText)
                return next(action)
            } catch (error) {
                store.dispatch(
                    createMeetingFailed({
                        header: 'Failed to delete meeting',
                        message: error.message,
                    }),
                )
                store.dispatch(setMeetingReqLoading({ isDeleteInProgress: false }))
                store.dispatch(setMeetingView({ view: MeetingViews.CREATE }))
            }
            return
        }
        case forgetMeeting.type: {
            store.dispatch(clearWidgetAlert())
            store.dispatch(setMeetingView({ view: MeetingViews.CREATE }))
            return next(action)
        }
        case setMeetingStatus.type: {
            try {
                const response = await videoMeetingAPI.updateMeetingStatus({
                    ...action.payload,
                    meetingID: meetingID ?? '',
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                })
                if (response.status !== 200) throw new Error(response.statusText)

                return next(action)
            } catch (error) {
                store.dispatch(
                    createMeetingFailed({
                        header: 'Failed to update meeting status',
                        message: error.message,
                    }),
                )
            }
            return
        }
        case createMeetingFailed.type: {
            store.dispatch({
                type: createNotification.type,
                payload: {
                    type: 'error',
                    header: action.payload.header ?? GENERIC_ERROR_HEADER,
                    message: action.payload.message ?? GENERIC_ERROR_MESSAGE,
                },
            })
            return next(action)
        }
        case getMeetingRecording.type: {
            try {
                try{
                    const response = await videoMeetingAPI.getMeetingRecording({
                        meetingID: action.payload.meetingID,
                        companyID: ID,
                        instanceID: instance?.ID,
                        token,
                    })

                    store.dispatch(getMeetingRecordingSuccess(response))
                } catch (error) {
                    if (JSON.parse(JSON.stringify(error)).status === 403) { // .status is not accessible on original error object so it neeeds to ne stringified and parsed
                        const response = await videoMeetingAPI.getMeetingRecordingTempAccess({
                            meetingID: action.payload.meetingID,
                            companyID: ID,
                            instanceID: instance?.ID,
                            token,
                        })
    
                        store.dispatch(getMeetingRecordingSuccess(response))  
                    }else{
                        throw error
                    }
                }
            } catch (error) {
                if (!action.payload.displayNotificationOnFailure) return next(action)
                console.error('Error getting recording', error)
                store.dispatch(
                    createNotification({
                        header: 'Error getting recording',
                        text: error.message,
                        type: 'error',
                    }),
                )
            }
            return next(action)
        }
        case saveScreenshot.type: {
            try {
                store.dispatch(saveScreenshotStart(action.payload))

                const presignedURLData = await videoMeetingAPI.getScreenshotUploadURL({
                    companyID: ID,
                    instanceID: instance?.ID,
                    token,
                    meetingID: meetingID ?? '',
                    contactID: contactID ?? '',
                })

                await videoMeetingAPI.uploadScreenshot({
                    url: presignedURLData.presignedUrl,
                    imageData: action.payload.imageData,
                })

                store.dispatch(
                    saveScreenshotSuccess({
                        s3Key: presignedURLData.s3Key,
                        src: action.payload.imageData,
                        isHistorical: !!action.payload.isHistorical,
                        attributeAttachmentCount: action.payload.attributeAttachmentCount,
                    }),
                )
            } catch (error) {
                console.error('Error creating meeting', error)
                store.dispatch(
                    saveScreenshotFailed({ isHistorical: !!action.payload.isHistorical }),
                )
            }
            return next(action)
        }
        case saveScreenshotSuccess.type: {
            if (!contactID) return next(action)
            const attachmentCount = Object.keys(screenshots ?? {}).length
            store.dispatch(
                updateContactAttributes(
                    contactID,
                    {
                        'sa-video-meeting-attachment-count': updateAttachmentCount(
                            contactID,
                            action.payload.attributeAttachmentCount,
                            attachmentCount,
                        ),
                    },
                    true,
                ),
            )
            return next(action)
        }

        case saveScreenshotFailed.type: {
            store.dispatch({
                type: createNotification.type,
                payload: {
                    type: 'error',
                    header: 'Screenshot failed',
                    message: 'Failed to upload screenshot',
                },
            })
            return next(action)
        }

        case deleteScreenshots.type: {
            const newScreenshots = { ...screenshots }
            try {
                await Promise.all(
                    Object.keys(action.payload.screenshots).map(async (key) => {
                        await videoMeetingAPI.deleteScreenshot({
                            companyID: ID,
                            instanceID: instance?.ID,
                            token,
                            s3Key: key,
                        })
                        delete newScreenshots[key]
                    }),
                )
            } catch (error) {
                store.dispatch({
                    type: createNotification.type,
                    payload: {
                        type: 'error',
                        header: 'Screenshot Error',
                        message: 'Failed to delete screenshot(s)',
                    },
                })
            }
            store.dispatch(
                deleteScreenshotsCompleted({ ...action.payload, screenshots: newScreenshots }),
            )
            const attachmentCount = Object.keys(newScreenshots).length
            store.dispatch(
                updateContactAttributes(
                    contactID!,
                    {
                        'sa-video-meeting-attachment-count': updateAttachmentCount(
                            contactID!,
                            action.payload.attributeAttachmentCount,
                            attachmentCount,
                        ),
                    },
                    true,
                ),
            )
            return next(action)
        }

        case getScreenshots.type: {
            const { meetingID, isHistorical } = action.payload

            try {
                const screenshots = await videoMeetingAPI.getScreenshots({
                    meetingID,
                    token,
                    companyID: ID,
                    instanceID: instance?.ID,
                })
                store.dispatch(
                    getScreenshotsCompleted({
                        screenshots,
                        isHistorical: !!isHistorical,
                    }),
                )
            } catch (error) {
                store.dispatch({
                    type: createNotification.type,
                    payload: {
                        type: 'error',
                        header: 'Screenshot Error',
                        message: 'Failed to get screenshot(s)',
                    },
                })
            }

            return next(action)
        }
    }

    return next(action)
}
export default videoMeetingMiddleware
