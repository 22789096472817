import { Button } from '@missionlabs/smartagent-app-components'
import React from 'react'
import ExitFullScreenIcon from '../../images/icon-exit-fullscreen.svg'
import FullScreenIcon from '../../images/icon-fullscreen.svg'
import ZoomInIcon from '../../images/icon-zoom-in.svg'
import ZoomOutIcon from '../../images/icon-zoom-out.svg'
import ZoomResetIcon from '../../images/icon-zoom-reset.svg'
import { ControlType } from './interfaces/VideoControls'
import { IZoomAndFSBtnsProps } from './interfaces/ZoomAndFullScreenButtons'

const ZoomAndFullScreenButtons: React.FC<IZoomAndFSBtnsProps> = ({
    videoZoomControls,
    isFullScreen,
    handleFullScreen,
}) => {
    const controls: ControlType[] = [
        {
            icon: ZoomInIcon,
            dataTestId: 'zoom-in-button',
            title: 'Zoom In',
            alt: 'Zoom In',
            onClick: () => videoZoomControls.zoomIn(),
        },
        {
            icon: ZoomOutIcon,
            dataTestId: 'zoom-out-button',
            title: 'Zoom Out',
            alt: 'Zoom Out',
            onClick: () => videoZoomControls.zoomOut(),
        },
        {
            icon: ZoomResetIcon,
            dataTestId: 'zoom-reset-button',
            title: 'Zoom Reset',
            alt: 'Zoom Reset',
            onClick: () => {
                videoZoomControls.centerView(), videoZoomControls.setTransform(0, 0, 1)
            },
        },

        {
            icon: isFullScreen ? ExitFullScreenIcon : FullScreenIcon,
            dataTestId: 'fullscreen-button',
            title: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
            alt: isFullScreen ? 'Exit Fullscreen' : 'Fullscreen',
            onClick: handleFullScreen,
        },
    ]
    return controls.map(({ icon, alt, title, onClick, disabled, dataTestId }, index) => {
        return (
            <Button
                data-testid={dataTestId}
                key={`${index}-${icon}`}
                disabled={disabled}
                onClick={onClick}
            >
                <img src={icon} alt={alt} title={title} />
            </Button>
        )
    })
}

export default ZoomAndFullScreenButtons
