import { Button, Popup } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import { body, heading } from '../constants/deleteSRConfirmationPortal'
import {
    DeleteSRConfirmationPortalBtn,
    IDeleteSRConfirmationPortalProps,
} from '../interfaces/DeleteConfirmationPortal'

const DeleteSRConfirmationPortal: React.FC<IDeleteSRConfirmationPortalProps> = ({ actions }) => {
    const buttons: DeleteSRConfirmationPortalBtn[] = [
        {
            text: 'Cancel',
            styling: 'white',
            alt: 'Cancel',
            title: 'Cancel',
            testId: 'cancel-button',
            onClick: actions.handleClosePortal,
        },
        {
            text: 'Delete',
            styling: 'red',
            alt: 'Delete',
            title: 'Delete',
            testId: 'delete-button',
            onClick: actions.handleDeletion,
        },
    ]

    return (
        <Popup className="video-popup" center>
            <H>{heading}</H>
            <p>{body}</p>
            <span className="button-wrapper">
                {buttons.map(({ text, alt, title, styling, onClick, testId }, index) => {
                    return (
                        <Button
                            data-testid={testId}
                            key={`${index}-${alt}`}
                            alt={alt}
                            title={title}
                            large
                            styling={styling}
                            onClick={onClick}
                        >
                            {text}
                        </Button>
                    )
                })}
            </span>
        </Popup>
    )
}

export default DeleteSRConfirmationPortal
