import { BehaviorType } from '@aws-sdk/client-connect'
import ContactState from 'store/contact/contact.state'
import { IRoutingProfile } from 'store/settings/settings.state'

export const getRoutingProfile = (routingProfiles: IRoutingProfile[], ARN: string) => {
    return routingProfiles.find((routingProfile) => routingProfile.Arn === ARN)!
}

export const getContactsSum = (contacts: ContactState[]) =>
    contacts
        .filter((contact) => contact.channel !== connect.ChannelType.VOICE)
        .reduce(
            (acc, { channel }) => {
                if (acc[channel]) {
                    acc[channel] += 1
                } else {
                    acc[channel] = 1
                }

                return acc
            },
            {} as Record<string, number>,
        )

export const getNonRoutableStates = (states: connect.AgentStateDefinition[] = []) =>
    states
        .filter(
            (state) =>
                state.type === connect.AgentStateType.NOT_ROUTABLE ||
                state.type === connect.AgentStateType.OFFLINE,
        )
        .map((state) => state.agentStateARN)

export interface IMediaConcurrency {
    isCrossChannel: boolean
    concurrency: number
}

export const getMediaConcurrencyHash = (mediaConcurrency: Record<string, any>[]) =>
    mediaConcurrency ? mediaConcurrency.reduce(
        (acc, mediaConcurrency) => {
            acc[mediaConcurrency.Channel] = {
                isCrossChannel:
                    mediaConcurrency.CrossChannelBehavior?.BehaviorType === BehaviorType.ROUTE_ANY_CHANNEL,
                concurrency: mediaConcurrency.Concurrency,
            }

            return acc
        },
        {} as Record<string, IMediaConcurrency>,
    ) : {}
