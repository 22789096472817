import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useAgents from 'hooks/redux/useAgents'

import { setSelectedInteraction, updateScreenRecording } from 'store/contactSearch/contactSearch.actions'
import { filterInteractionsByCallAndHoldDuration } from 'store/contactSearch/contactSearch.utils'
import RootState from 'store/state'

import ContactSearch from './ContactSearch'
import ContactSearchResults from './ContactSearchResults'
import InteractionView from './InteractionView'

import updateDocumentTitle from 'utils/updateDocumentTitle'
import './historic-metrics.scss'

export interface Props {
    isWidget?: boolean
}

const HistoricMetrics: React.FC<Props> = ({ isWidget }) => {
    updateDocumentTitle('Contact Search - Metrics - SmartAgent')

    const dispatch = useDispatch()

    const formParams = useSelector((state: RootState) => state.contactSearch.formParams)
    const interactions = useSelector((state: RootState) => state.contactSearch.interactions)
    const interactionsLoading = useSelector(
        (state: RootState) => state.contactSearch.interactionsLoading,
    )
    const selectedInteraction = useSelector(
        (state: RootState) => state.contactSearch.selectedInteraction,
    )

    const interactionsFiltered = filterInteractionsByCallAndHoldDuration(interactions, formParams)
    const { agentsActive } = useAgents()

    const clearInteraction = () => {
        dispatch(setSelectedInteraction(undefined))
        dispatch(updateScreenRecording({ signedUrl: ''}));
    }

    return (
        <>
            {selectedInteraction ? (
                <InteractionView
                    interaction={selectedInteraction}
                    clearInteraction={clearInteraction}
                    isWidget={isWidget}
                />
            ) : (
                <div
                    aria-hidden={agentsActive}
                    aria-live="polite"
                    aria-label="Historic metrics page loaded"
                    className={`${!isWidget ? 'panel fixed-height alt row column' : ''}`}
                    id="skip-to-content"
                >
                    <ContactSearch searching={interactionsLoading} />

                    {!interactionsLoading && (
                        <ContactSearchResults
                            interactions={interactionsFiltered}
                            channel={formParams.channel}
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default HistoricMetrics
